import request from "@/request.js";

const BASE = "/qsser";

export default {
  getAll() {
    return request.get(BASE);
  },

  getNcs() {
    return request.get(BASE + "/ncs");
  },

  getOldNcs() {
    return request.get(BASE + "/ncs_old");
  },

  getVisites() {
    return request.get(BASE + "/visites");
  },

  getOldVisites() {
    return request.get(BASE + "/visites_old");
  },

  getEvenements() {
    return request.get(BASE + "/evenements");
  },

  getOldEvenements() {
    return request.get(BASE + "/evenements_old");
  },

  getActions() {
    return request.get(BASE + "/actions");
  },

  getOldActions() {
    return request.get(BASE + "/actions_old");
  },

  getAnimations() {
    return request.get(BASE + "/animations");
  },

  getOldAnimations() {
    return request.get(BASE + "/animations_old");
  },

  getData() {
    return request.get(BASE + "/data");
  },

  createNcs() {
    return request.put(BASE + "/ncs");
  },
  updateNcs(payload) {
    return request.post(BASE + "/ncs", {
      id: payload.id,
      nc_reference: payload.nc_reference,
      nc_description: payload.nc_description,
      nc_identifiant: payload.nc_identifiant,
      nc_libelle: payload.nc_libelle,
      nc_date: payload.nc_date,
      nc_type_id: payload.nc_type_id,
      nc_origine_id: payload.nc_origine_id,
      nc_affaire_id: payload.nc_affaire_id,
      nc_user_id: payload.nc_user_id,
      nc_cause_id: payload.nc_cause_id,
      nc_societe_id: payload.nc_societe_id,
      nc_faits: payload.nc_faits,
      nc_causes: payload.nc_causes,
      nc_derogation: payload.nc_derogation,
      nc_derogation_description: payload.nc_derogation_description,
      nc_derogation_justification: payload.nc_derogation_justification,
      nc_cloture: payload.nc_cloture,
    });
  },
  deleteNcs(id) {
    return request.delete(BASE + "/ncs" + "/" + id);
  },

  createVisites() {
    return request.put(BASE + "/visites");
  },
  updateVisites(payload) {
    return request.post(BASE + "/visites", {
      id: payload.id,
      visite_date: payload.visite_date,
      visite_affaire_id: payload.visite_affaire_id,
      visite_site_id: payload.visite_site_id,
      visite_pilote_id: payload.visite_pilote_id,
      visite_societe_id: payload.visite_societe_id,
      visite_synthese: payload.visite_synthese,
    });
  },
  deleteVisites(id) {
    return request.delete(BASE + "/visites" + "/" + id);
  },

  createEvenements() {
    return request.put(BASE + "/evenements");
  },
  updateEvenements(payload) {
    return request.post(BASE + "/evenements", {
      id: payload.id,
      evenement_date: payload.evenement_date,
      evenement_site_client_id: payload.evenement_site_client_id,
      evenement_pilote_id: payload.evenement_pilote_id,
      evenement_societe_id: payload.evenement_societe_id,
      evenement_type_id: payload.evenement_type_id,
      evenement_risque_id: payload.evenement_risque_id,
      evenement_classement_id: payload.evenement_classement_id,
      evenement_remarques: payload.evenement_remarques,
    });
  },
  deleteEvenements(id) {
    return request.delete(BASE + "/evenements" + "/" + id);
  },
  exportEvenements() {
    return request.get(BASE + "/evenements-export");
  },
  createAnimations() {
    return request.put(BASE + "/animations");
  },
  updateAnimations(payload) {
    return request.post(BASE + "/animations", {
      id: payload.id,
      anim_societe_id: payload.anim_societe_id,
      anim_pilote_id: payload.anim_pilote_id,
      anim_theme_id: payload.anim_theme_id,
      anim_type_id: payload.anim_type_id,
      anim_date: payload.anim_date,
      anim_commentaire: payload.anim_commentaire,
    });
  },
  deleteAnimations(id) {
    return request.delete(BASE + "/animations" + "/" + id);
  },
  exportAnimations() {
    return request.get(BASE + "/animations-export");
  },

  getParticipants(id) {
    return request.get(BASE + "/animation_participants" + "/" + id);
  },

  createParticipant(payload) {
    return request.put(BASE + "/animation_participants", payload);
  },

  deleteParticipant(id) {
    return request.delete(BASE + "/animation_participants" + "/" + id);
  },

  createActions(payload) {
    return request.put(BASE + "/actions", {
      action_origine_system_id: payload.action_origine_system_id,
      action_origine_id: payload.action_origine_id,
    });
  },
  updateActions(payload) {
    return request.post(BASE + "/actions", {
      id: payload.id,
      description: payload.description,
      action_origine_system_id: payload.action_origine_system_id,
      action_origine_id: payload.action_origine_id,
      action_etat_id: payload.action_etat_id,
      action_type_id: payload.action_type_id,
      action_origineact_id: payload.action_origineact_id,
      action_pilote_id: payload.action_pilote_id,
      action_priorite_id: payload.action_priorite_id,
      action_debut: payload.action_debut,
      action_fin: payload.action_fin,
      action_delais: payload.action_delais,
      action_details: payload.action_details,
      action_verif_critere: payload.action_verif_critere,
      action_verif_etat_id: payload.action_verif_etat_id,
      action_verif_commentaire: payload.action_verif_commentaire,
    });
  },
  deleteActions(id) {
    return request.delete(BASE + "/actions" + "/" + id);
  },
  exportActions() {
    return request.get(BASE + "/actions-export");
    //
  },
};
