import request from "@/request.js";

const BASE = "/production";

export default {
  getAll() {
    return request.get(BASE);
  },
  getItem(id) {
    return request.get(BASE + "/" + id);
  },
  getData() {
    return request.get(BASE + "/data");
  },
  getByAffaire(affaire_id) {
    return request.get(BASE + "/byaffaire/" + affaire_id);
  },
  // ================================================
  create(payload) {
    console.log(payload)
    return request.put(BASE, {
      affaire_id: payload.affaire_id
    });
  },
  update(payload) {
    return request.post(BASE + "/" + payload.id, {
      affaire_id: payload.affaire_id,
      type_id: payload.type_id,
      mois: payload.mois,
      description: payload.description,
      montant: payload.montant,
      facture: payload.facture,
      commentaire: payload.commentaire,
    });
  },
  delete(id) {
    return request.delete(BASE + "/" + id);
  },
  updateProdChiffrage(payload) {
    return request.post(BASE + "/chiffrage/" + payload.id, {
      nbre_jours_travailles: payload.nbre_jours_travailles,
      avancement: payload.avancement,
      frais_produits: payload.frais_produits,
      production: payload.production,
    });
  },
};