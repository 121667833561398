import request from "@/request.js";

const BASE = "/materiels";

export default {
  getAll() {
    return request.get(BASE);
  },
  getData() {
    return request.get(BASE + "/data");
  },

  // ================================================

  createMateriel() {
    return request.put(BASE + "/materiel");
  },
  updateMateriel(payload) {
    return request.post(BASE + "/materiel", {
      id: payload.id,
      identifiant: payload.identifiant,
      description: payload.description,
      num_serie: payload.num_serie,
      lieu_stockage: payload.lieu_stockage,
      marque: payload.marque,
      modele: payload.modele,
      image: payload.image,
      materiel_etat_id: payload.materiel_etat_id,
      materiel_type_id: payload.materiel_type_id,
      materiel_collab_id: payload.materiel_collab_id,
      materiel_societe_id: payload.materiel_societe_id,
      materiel_fournisseur_id: payload.materiel_fournisseur_id,
      date_commande: payload.date_commande,
      date_livraison: payload.date_livraison,
      date_validite: payload.date_validite,
      commentaire: payload.commentaire,
      tags: payload.tags,
    });
  },
  deleteMateriel(payload) {
    return request.delete(BASE + "/materiel/" + payload.id);
  },
  exportMateriels() {
    return request.get(BASE + "/export");
  },
  // ================================================

  createMaterielVerif(payload) {
    return request.put(BASE + "/materielverif", {
      materiel_id: payload.id,
    });
  },
  updateMaterielVerif(payload) {
    return request.post(BASE + "/materielverif", {
      id: payload.id,
      materiel_id: payload.materiel_id,
      verif_type_id: payload.verif_type_id,
      date_debut: payload.date_debut,
      date_validite: payload.date_validite,
      fournisseur_id: payload.fournisseur_id,
      verif_interne: payload.verif_interne,
      commentaire: payload.commentaire,
    });
  },
  deleteMaterielVerif(payload) {
    return request.delete(BASE + "/materielverif/" + payload.id);
  },

  // ================================================

  createArticle() {
    return request.put(BASE + "/article");
  },
  updateArticle(payload) {
    return request.post(BASE + "/article", {
      id: payload.id,
      description: payload.description,
      specs: payload.specs,
      image: payload.image,
      stock_securite: payload.stock_securite,
      prix_unitaire_ht: payload.prix_unitaire_ht,
      fournisseur_id: payload.fournisseur_id,
      type_id: payload.type_id,
    });
  },
  deleteArticle(payload) {
    return request.delete(BASE + "/article/" + payload.id);
  },

  // ================================================

  createCommande() {
    return request.put(BASE + "/commande");
  },
  updateCommande(payload) {
    return request.post(BASE + "/commande", {
      id: payload.id,
      etat_id: payload.etat_id,
      fournisseur_id: payload.fournisseur_id,
      etablissement_livraison_id: payload.etablissement_livraison_id,
      adresse_livraison: payload.adresse_livraison,
      contact_livraison: payload.contact_livraison,
      societe_facturation_id: payload.societe_facturation_id,
      reference: payload.reference,
      validation: payload.validation,
      commentaire: payload.commentaire,
    });
  },
  deleteCommande(payload) {
    return request.delete(BASE + "/commande/" + payload.id);
  },

  // ================================================

  createCommandeArticle(payload) {
    return request.put(BASE + "/commande/article", {
      article_id: payload.article_id,
      commande_id: payload.commande_id,
      quantite: payload.quantite,
    });
  },
  updateCommandeArticle(payload) {
    return request.post(BASE + "/commande/article", {
      id: payload.id,
      article_id: payload.article_id,
      commande_id: payload.commande_id,
      quantite: payload.quantite,
      quantite_commande: payload.quantite_commande,
      information: payload.information,
      livraison_date: payload.livraison_date,
      livraison_quantite: payload.livraison_quantite,
      livraison_conforme: payload.livraison_conforme,
    });
  },
  deleteCommandeArticle(payload) {
    return request.delete(BASE + "/commande/article/" + payload.id);
  },

  // ================================================

  createStock() {
    return request.put(BASE + "/stock", {
      //article_id: payload.article_id,
      //quantite: payload.quantite
    });
  },
  updateStock(payload) {
    return request.post(BASE + "/stock", {
      id: payload.id,
      article_id: payload.article_id,
      quantite: payload.quantite,
      commentaire: payload.commentaire,
    });
  },
  deleteStock(payload) {
    return request.delete(BASE + "/stock/" + payload.id);
  },

  // ================================================

  createFournisseur() {
    return request.put(BASE + "/fournisseur", {});
  },

  updateFournisseur(payload) {

    //console.log(payload.societes);
    //console.log(Object.keys(payload.societes));

    return request.post(BASE + "/fournisseur", {
      id: payload.id,
      etat: payload.etat,
      date_debut: payload.date_debut,
      date_fin: payload.date_fin,
      description: payload.description,
      raison_sociale: payload.raison_sociale,
      activite: payload.activite,
      adresse: payload.adresse,
      telephone: payload.telephone,
      email: payload.email,
      type_service: payload.type_service,
      commentaire: payload.commentaire,
      agree: payload.agree,
      e_attestation: payload.e_attestation,
      certification_1: payload.certification_1,
      certification_2: payload.certification_2,
      certification_3: payload.certification_3,
      certification_4: payload.certification_4,
      certification_autre: payload.certification_autre,

      societes: Object.keys(payload.societes),

    });
  },

  deleteFournisseur(payload) {
    return request.delete(BASE + "/fournisseur/" + payload.id);
  },

  // ================================================

  createFournisseurEval(payload) {
    console.log(payload);
    return request.put(BASE + "/evaluation", {
      fournisseur_id: payload.id,
    });
  },

  updateFournisseurEval(payload) {
    return request.post(BASE + "/evaluation", {
      id: payload.id,
      fournisseur_id: payload.fournisseur_id,
      eval_date: payload.eval_date,
      eval_1: payload.eval_1,
      eval_2: payload.eval_2,
      eval_3: payload.eval_3,
      eval_4: payload.eval_4,
      eval_5: payload.eval_5,
      commentaire: payload.commentaire,
      evaluateur_id: payload.evaluateur_id,
    });
  },

  deleteFournisseurEval(payload) {
    return request.delete(BASE + "/evaluation/" + payload.id);
  },
};
