<template>
  <container-main id="app" class="app">
    <vue-snotify></vue-snotify>
    <navbar></navbar>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </container-main>
</template>

<script>
import Navbar from "@/components/application/Navbar.vue";
import ContainerMain from "@/components/containers/ContainerMain.vue";

export default {
  name: "app",
  components: { Navbar, ContainerMain },
  beforeCreate() {
    //this.$store.dispatch("initialiseStore");
  }
};
</script>

<style>
html body {
  max-width: 100vw;
}
.app {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.bg-light-home-1 {
  background: url(/images/cube-light.svg) no-repeat bottom left,
  linear-gradient(to bottom, #7192b6, #fff);
}

.bg-dark-home-1 {
  background: url(/images/cube-dark.svg) no-repeat bottom left,
    linear-gradient(to right top, #fff, #1b3565);
}

.bg-light-home-2 {
  background: linear-gradient(to bottom, #7192b6, #fff);
}

.bg-dark-home-2 {
  background: linear-gradient(to right top, #fff, #1b3565);
}

.vw100 {
  width: 100vw;
}

.vh100 {
  height: 100vh;
}

.mw100 {
  min-width: 100vw;
}

.mh100 {
  min-height: 100vh;
}

.rounded-xl {
  border-radius: 2rem !important;
}

.rounded-xl-top {
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.q-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);
  width: 100%;
}

.avatar {
  background-size: cover;
  background-position: center;
  border: 1px solid #fff;
  object-fit: cover;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.float-label label {
  font-size: 12.5px;
  color: #6c757d;
  font-weight: 400;
  margin: 0;
  opacity: 1;
}

.icon-container {
  position: absolute;
  bottom: 12px;
  right: 0;
  height: 1.3rem;
}

.icon-btn {
  margin: 2px;
  height: 1.2rem;
}

/* Change Autocomplete styles in Chrome*/
:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

.modal-content {
  border: none !important;
}

.icon-rotate {
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
  stroke: #007bff;
}

.modal-body {
  padding: 1rem 1rem 0 1rem;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  color: white;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: black;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.tooltip-inner {
  max-width: none !important;
}
.popover {
  max-width: none !important;
  border: none;
}

.vdp-datepicker {
  z-index: 2000;
}

.qwark-border-light {
  border-bottom: 1px solid #dfe3e5 !important;
}
.qwark-border-dark {
  border-bottom: 1px solid #24292d !important;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
}
.apexcharts-canvas.apexcharts-theme-dark {
  background: none !important;
}

.hover-primary:hover {
  color: #007bff !important;
}
</style>
