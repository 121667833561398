import api from "@/services/api/clients.js";
import { getField, updateField } from "vuex-map-fields";
// initial state
const state = {
  clients: [],
  client: {},
  data: {},
  suivis: [],
  satisfactions: [],
  rdvs: [],
  users: []
};

// getters
const getters = {
  // Toutes les clients
  clients: function (state) {
    return state.clients;
  },
  client: function (state) {
    return state.client;
  },
  data: function (state) {
    return state.data;
  },
  getField
};

// actions
const actions = {
  getClients({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then(response => {
          resolve(response);
          commit("setClients", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getClient({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getItem(id)
        .then(response => {
          resolve(response);
          commit("setClient", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getData()
        .then(response => {
          resolve(response);
          commit("setData", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .create(data)
        .then((response) => {
          commit("create", response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      api
        .update(data)
        .then(response => {
          commit("update", response.data);
          resolve(state.client);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  delete({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .delete(state.client.id)
        .then(() => {
          commit("delete");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createSuivi({ commit }, affaire_id) {
    return new Promise((resolve, reject) => {
      api
        .createSuivi(affaire_id)
        .then(response => {
          commit("createSuivi", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateSuivi({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateSuivi(data)
        .then(() => {
          commit("updateSuivi");
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  deleteSuivi({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteSuivi(data.id)
        .then(() => {
          commit("deleteSuivi", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  createSatisfaction({ commit }, client_id) {
    return new Promise((resolve, reject) => {
      api
        .createSatisfaction(client_id)
        .then(response => {
          commit("createSatisfaction", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateSatisfaction({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateSatisfaction(data)
        .then(() => {
          commit("updateSatisfaction");
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  deleteSatisfaction({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteSatisfaction(data.id)
        .then(() => {
          commit("deleteSatisfaction", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  createRdv({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createRdv(data)
        .then(response => {
          commit("createRdv", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateRdv({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateRdv(data)
        .then(() => {
          commit("updateRdv");
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  deleteRdv({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteRdv(data.id)
        .then(() => {
          commit("deleteRdv", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  autorizeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .autorizeUser(data)
        .then(() => {
          commit("autorizeUser", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  unautorizeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .unautorizeUser(data)
        .then(() => {
          commit("unautorizeUser", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
};

// mutations
const mutations = {
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setClients(state, data) {
    state.clients = data;
  },

  /**
* Mutation
* @return {state}
* @param {object} state
*/
  setCompte(state, data) {
    state.client.client_compte_id = data.id
    state.client.client_comptes = data
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setClient(state, data) {
    state.client = data;
    state.suivis = data.client_suivis;
    state.satisfactions = data.client_satisfactions;
    state.rdvs = data.client_rdvs;
    state.users = data.client_users;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setData(state, data) {
    state.data = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  create(state, data) {
    state.clients.push(data);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
  */
  update(state) {
    //let client = state.clients.indexOf(state.client);
    const index = state.clients.findIndex(x => x.id === state.client.id)
    state.clients[index].client_nom = state.client.client_nom
    state.clients[index].client_prenom = state.client.client_prenom
    state.clients[index].client_compte_id = state.client.client_compte_id
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  delete(state) {
    const index = state.clients.findIndex(x => x.id === state.client.id)
    state.clients.splice(index, 1);
    state.client = {}
  },

  updateField,

  /**
  * Mutation
  * @return {state}
  * @param {object} state
  */
  createSuivi(state, data) {
    state.suivis.push({
      id: data.id,
      suivi_date: null,
      suivi_client_id: data.suivi_client_id,
      description: "",
      commentaire: ""
    });
  },

  updateSuivi() {

  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteSuivi(state, data) {
    const index = state.client.client_suivis.findIndex(x => x.id === data.id)
    state.client.client_suivis.splice(index, 1);

    const index2 = state.suivis.findIndex(x => x.id === data.id)
    state.suivis.splice(index2, 1);
  },

  /**
  * Mutation
  * @return {state}
  * @param {object} state
  */
  createSatisfaction(state, data) {
    state.satisfactions.push({
      id: data.id,
      satisfaction_affaire_id: null,
      satisfaction_date: null,
      satisfaction_client_id: data.satisfaction_client_id,
      satisfaction_description: "",
      satisfaction_note_commerce: 0,
      satisfaction_note_pilotage: 0,
      satisfaction_note_technique: 0,
      satisfaction_note_qsser: 0,
      satisfaction_note_prix: 0,
      satisfaction_commentaire: ""
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateSatisfaction() {
    //const index = state.client.client_satisfactions.findIndex(x => x.id === data.id)
    //state.client.client_satisfactions[index] = data
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteSatisfaction(state, data) {
    const index = state.client.client_satisfactions.findIndex(x => x.id === data.id)
    state.client.client_satisfactions.splice(index, 1);

    const index2 = state.satisfactions.findIndex(x => x.id === data.id)
    state.satisfactions.splice(index2, 1);
  },

  /**
  * Mutation
  * @return {state}
  * @param {object} state
  */
  createRdv(state, data) {
    state.rdvs.push({
      id: data.id,
      rdv_date: null,
      rdv_client_id: data.rdv_client_id,
      rdv_type_id: null,
      rdv_notes: ""
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateRdv() {
    //const index = state.client.client_rdvs.findIndex(x => x.id === data.id)
    //state.client.client_rdvs[index] = data
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteRdv(state, data) {
    const index = state.client.client_rdvs.findIndex(x => x.id === data.id)
    state.client.client_rdvs.splice(index, 1);

    const index2 = state.rdvs.findIndex(x => x.id === data.id)
    state.rdvs.splice(index2, 1);
  },

  autorizeUser(state, data) {
    state.users.push({
      user_id: data.user_id
    })
  },

  unautorizeUser(state, data) {
    const index = state.users.findIndex(x => x.user_id === data.user_id)
    state.users.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
