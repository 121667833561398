import request from "@/request.js";

const BASE = "/affaires";

export default {
  getAll() {
    return request.get(BASE);
  },
  getItem(id) {
    return request.get(BASE + "/" + id);
  },
  getData() {
    return request.get(BASE + "/data");
  },
  getPcr() {
    return request.get(BASE + "/pcr");
  },
  // ================================================
  createAffaire(payload) {
    return request.put(BASE, payload);
  },
  updateAffaire(id, payload) {
    return request.post(BASE + "/" + id, payload);
  },
  deleteAffaire(id) {
    return request.delete(BASE + "/" + id);
  },
  // ================================================
  createPhase(payload) {
    return request.put(BASE + "/phase", { phase_affaire_id: payload });
  },
  updatePhase(payload) {
    //console.log('payload', payload)

    const event = new Date(payload.start_date);
    const _start_date = event.toLocaleDateString("en-US");

    return request.post(BASE + "/phase", {
      id: payload.id,
      phase_affaire_id: payload.phase_affaire_id,
      text: payload.text,
      start_date: _start_date,
      duration: payload.duration,
      progress: payload.progress,
      phase_prix_vente: payload.phase_prix_vente
    });
  },
  deletePhase(payload) {
    return request.delete(BASE + "/phase" + "/" + payload.id);
  },

  createPhaseLink(payload) {
    return request.put(BASE + "/phase/link", {
      affaire_id: payload.affaire_id,
      source: payload.source,
      target: payload.target,
      type: payload.type
    });
  },

  deletePhaseLink(payload) {
    return request.delete(BASE + "/phase/link/" + payload.id);
  },

  // ================================================
  createSuivi(affaire_id) {
    return request.put(BASE + "/suivi", { visite_affaire_id: affaire_id });
  },
  updateSuivi(payload) {
    return request.post(BASE + "/suivi" + "/" + payload.id, {
      visite_date: payload.visite_date,
      visite_affaire_id: payload.visite_affaire_id,
      visite_site_id: payload.visite_site_id,
      visite_pilote_id: payload.visite_pilote_id,
      visite_societe_id: payload.visite_societe_id,
      visite_synthese: payload.visite_synthese
    });
  },
  deleteSuivi(id) {
    return request.delete(BASE + "/suivi" + "/" + id);
  },
  createSatisfaction(data) {
    return request.put(BASE + "/satisfaction", {
      satisfaction_affaire_id: data.affaire_id,
      satisfaction_client_id: data.client_id
    });
  },
  updateSatisfaction(payload) {
    return request.post(BASE + "/satisfaction", {
      id: payload.id,
      satisfaction_date: payload.satisfaction_date,
      satisfaction_client_id: payload.satisfaction_client_id,
      satisfaction_description: payload.satisfaction_description,
      satisfaction_note_commerce: payload.satisfaction_note_commerce,
      satisfaction_note_pilotage: payload.satisfaction_note_pilotage,
      satisfaction_note_technique: payload.satisfaction_note_technique,
      satisfaction_note_qsser: payload.satisfaction_note_qsser,
      satisfaction_note_prix: payload.satisfaction_note_prix,
      satisfaction_affaire_id: payload.satisfaction_affaire_id,
      satisfaction_commentaire: payload.satisfaction_commentaire
    });
  },
  deleteSatisfaction(id) {
    return request.delete(BASE + "/satisfaction" + "/" + id);
  },
  autorizeUser(payload) {
    return request.post(BASE + "/users/autorize", {
      user_id: payload.user_id,
      affaire_id: payload.affaire_id
    });
  },
  unautorizeUser(payload) {
    return request.post(BASE + "/users/unautorize", {
      user_id: payload.user_id,
      affaire_id: payload.affaire_id
    });
  }
};
