<template>
  <form
    :id="id"
    class="d-flex flex-column rounded-xl-top flex-grow-1 p-2 p-md-3"
    :class="{ 'shadow-form': shadow }"
    v-background-3
    v-on:submit.prevent
  >
    <slot></slot>
    <div class="d-flex flex-column flex-md-column align-items-center">
      <div class="d-flex flex-row align-items-center ml-md-auto">
        <div class="mr-md-3" v-if="statut">
          <small>{{ statut }}</small>
        </div>
        <button
          class="btn btn-secondary m-1 pb-2"
          type="button"
          :disabled="loading"
          v-if="backBtn"
          @click="$emit('back')"
          v-tooltip="'Retour'"
        >
          <div class="d-flex">
            <base-icon name="arrow-left" class></base-icon>
            <span class="ml-2">Annuler</span>
          </div>
        </button>
        <button
          class="btn btn-danger m-1 pb-2"
          type="button"
          :disabled="loading"
          v-if="deleteBtn"
          @click="$emit('delete')"
          v-tooltip="'Supprimer'"
        >
          <div class="d-flex">
            <base-icon name="trash-2" class></base-icon>
            <span class="ml-2">Supprimer</span>
          </div>
        </button>
        <button
          class="btn btn-primary m-1 pb-2"
          v-if="updateBtn"
          type="button"
          :disabled="loading"
          @click="$emit('click')"
        >
          <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
            v-if="loading"
          ></span>
          <span class v-if="loading">Chargement...</span>
          <span class v-if="!loading">
            <div class="d-flex">
              <base-icon name="save" class></base-icon>
              <span class="ml-2">Enregistrer</span>
            </div>
          </span>
        </button>
        <button
          class="btn btn-success m-1 pb-2"
          type="button"
          :disabled="loading"
          v-if="selectBtn"
          @click="$emit('select')"
          v-tooltip="'Selectionner'"
        >
          <base-icon name="check" class></base-icon>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "BaseForm",
  components: { BaseIcon },
  data() {
    return {
      id: null
    };
  },
  props: {
    url: {
      type: String,
      required: false
    },
    data: {
      type: Object,
      required: false
    },
    btnSubmitText: {
      type: String,
      required: false,
      default: "Enregistrer"
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    updateBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    deleteBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    backBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    selectBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    statut: {
      type: String,
      required: false,
      default: ""
    },
    shadow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    test: function() {}
  },
  mounted() {
    this.id = this._uid;
  }
};
</script>
<style>
.shadow-form {
  box-shadow: 0rem -0.115rem 0.25rem rgba(0, 0, 0, 0.045) !important;
}
</style>
