<template>
  <div class="p-5">
    <p>{{ msg }}</p>
  </div>
</template>

<script>

export default {
  name: 'page404',
  data () {
    return {
      msg: 'ERREUR 404'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
