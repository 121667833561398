/************************************************
 *
 * FILTRE POUR LES TABLEAUX CONTENANT DES OBJETS
 * ex : [id:63, nom:garcia, prenom:florent...]
 *
 * @return {object} : array filtré
 *
 * @param {object} arr : array à filtrer
 * @param {object} obj : champ cible
 * @param {object} val : texte recherché
 *
 ************************************************/

import moment from "moment";

export function filterItems(arr, obj, val) {
  return arr.filter((item) => {
    return item[obj].toLowerCase().indexOf(val.toLowerCase()) > -1;
  });
}

export function filterFloat(value) {
  if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) return Number(value);
  return 0;
}

export function cloneObj(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function errHandler(error) {
  if (error.response && error.response.status === 422) {
    return error.response.data.error;
  }
}

export function lastContratForCollab(collab) {
  // Initialisation
  let activeContrat = {};
  let activeVersion = {};
  let contratVersions = [];
  let versionSelected = {};

  if (collab && collab.collaborateur_contrats_all && collab.collaborateur_contrats_all.length >= 1) {
    let __contrat = collab.collaborateur_contrats_all;
    let max_dtObj1 = moment(__contrat[0].contrat_date_debut);
    let indexContratMaxDate = 0;

    // Identifier l'index max sur la date de début
    __contrat.forEach((dt, index) => {
      if (dt && dt.contrat_date_debut && moment(dt.contrat_date_debut) > max_dtObj1) {
        max_dtObj1 = moment(dt.contrat_date_debut);
        indexContratMaxDate = index;
      }
    });

    // Get versions et le dernier contrat
    if (__contrat[indexContratMaxDate].contrat_version_all) {
      contratVersions = __contrat[indexContratMaxDate].contrat_version_all;
    }

    // Obtenir la dernière version
    // -----------------------------------------------------------------------------------
    // ANALYSE DES VERSIONS DU CONTRAT
    // -----------------------------------------------------------------------------------

    // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
    if (contratVersions.length >= 1) {
      let max_dtObj = moment(contratVersions[0].date_effet);
      let indexVersionMaxDate = 0;

      // Identifier l'index max sur la date d'effet
      contratVersions.forEach((dt, index) => {
        if (moment(dt.date_effet) > max_dtObj) {
          max_dtObj = moment(dt.date_effet);
          indexVersionMaxDate = index;
        }
      });

      // Dernière version basée sur la date d'effet
      versionSelected = contratVersions[indexVersionMaxDate];
    }

    activeContrat = __contrat[indexContratMaxDate];
    activeVersion = versionSelected;
  } else {
    activeContrat = {};
    activeVersion = {};
  }

  return {
    activeContrat: activeContrat,
    activeVersion: activeVersion,
  };
}
