import api from "@/services/api/user.js";
import { getField, updateField } from "vuex-map-fields";

// initial state
const state = {
  connected: localStorage.getItem("connected"),
  id: localStorage.getItem("user_id"),
  name: localStorage.getItem("name"),
  email: localStorage.getItem("email"),
  avatar: localStorage.getItem("avatar"),
  token_type: null,
  expires_in: null,
  access_token: localStorage.getItem("token"),
  refresh_token: "",
  users: JSON.parse(localStorage.getItem("users")),
  user_roles: JSON.parse(localStorage.getItem("user_roles")),
  user_permissions: JSON.parse(localStorage.getItem("user_permissions")),
  password_changed: JSON.parse(localStorage.getItem("password_changed")),
};

// getters
const getters = {
  /**
   * Mode
   * @return {boolean}
   * @param {object} state
   */
  connected: function (state) {
    return state.connected;
  },

  /**
   * Data
   * @return {string} access token
   * @param {object} state
   */
  token: function (state) {
    return state.access_token;
  },

  /**
   * Data
   * @return {string} access token
   * @param {object} state
   */
  avatar: function (state) {
    return state.avatar;
  },

  /**
   * Data
   * @return {object} user data
   * @param {object} state
   */
  data: function (state) {
    return state;
  },

  /**
   * Data
   * @return {object} user data
   * @param {object} state
   */
  havePermission: (state) => (name) => {
    return state.user_permissions.find((perm) => perm.name === name);
  },

  /**
   * Data
   * @return {object} user data
   * @param {object} state
   */
  haveRole: (state) => (name) => {
    return state.user_roles.find((role) => role.name === name);
  },

  /**
   * Data
   * @return {object} user data
   * @param {object} state
   */
  passwordChanged: function (state) {
    return state.password_changed;
  },

  /**
   * Data
   * @return {object} user data
   * @param {object} state
   */
  users: function (state) {
    return state.users;
  },

  /**
   * Data
   * @return {object} user data
   * @param {object} state
   */
  getField,
};

// actions
const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      api
        .login(user)
        .then((response) => {
          commit("connect", response.data);
          resolve(response);
        })
        .catch((error) => {
          commit("disconnect");
          reject(error);
        });
    });
  },
  changePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .changePassword(data)
        .then((response) => {
          commit("changePassword");
          resolve(response);
        })
        .catch((error) => {
          //commit("disconnect");
          reject(error);
        });
    });
  },
  logout({ commit }) {
    commit("disconnect");
  },
};

// mutations
const mutations = {
  updateField,
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  connect(state, data) {
    state.connected = true;
    state.id = data.id;
    state.name = data.collaborateur_nom + " " + data.collaborateur_prenom;
    state.email = data.collaborateur_email_pro;
    state.avatar = data.collaborateur_avatar;
    state.token_type = data.token_type;
    state.expires_in = data.expires_in;
    state.access_token = data.access_token;
    state.refresh_token = data.refresh_token;
    state.user_roles = data.user_roles;
    state.user_permissions = data.user_permissions;
    state.users = data.users;
    state.password_changed = data.password_changed;

    localStorage.setItem("connected", state.connected);
    localStorage.setItem("token", state.access_token);
    localStorage.setItem("user_id", state.id);
    localStorage.setItem("name", state.name);
    localStorage.setItem("email", state.email);
    localStorage.setItem("avatar", state.avatar);
    localStorage.setItem("users", JSON.stringify(state.users));
    localStorage.setItem("user_roles", JSON.stringify(state.user_roles));
    localStorage.setItem("user_permissions", JSON.stringify(state.user_permissions));
    localStorage.setItem("password_changed", state.password_changed);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  disconnect(state) {
    state.connected = false;
    state.id = null;
    state.name = null;
    state.email = null;
    state.avatar = null;
    state.token_type = null;
    state.expires_in = null;
    state.access_token = "";
    state.refresh_token = "";
    state.user_roles = [];
    state.user_permissions = [];
    state.users = [];

    localStorage.removeItem("connected");
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("avatar");
    localStorage.removeItem("users");
    localStorage.removeItem("user_roles");
    localStorage.removeItem("user_permissions");
    localStorage.removeItem("password_changed");
  },
  changePassword(state) {
    state.password_changed = true;
    localStorage.setItem("password_changed", state.password_changed);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
