import Vue from "vue";
import Router from "vue-router";
import store from "./store/index";

import Home from "@/views/Home.vue";
import Page404 from "@/views/Page404.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Accueil",
      component: Home,
    },
    {
      path: "/admin",
      name: "Admin",
      component: () => import("./views/Admin.vue"),
      meta: { role: "admin" },
    },
    {
      path: "/ma-page",
      name: "Profil",
      component: () => import("./views/Profil.vue"),
    },
    {
      path: "/ma-page/formations/:id",
      name: "Formation",
      component: () => import("./views/Formations.vue"),
    },
    {
      path: "/qualification/:url",
      name: "Qualification",
      component: () => import("./views/Qualifications.vue"),
    },
    {
      path: "/candidats",
      name: "Candidats",
      component: () => import("./views/Candidats.vue"),
      meta: { permission: "manage_candidats" },
    },
    {
      path: "/cse",
      name: "Cse",
      component: () => import("./views/Cse.vue"),
      meta: { permission: ["manage_cse", "manage_cse_techno", "manage_cse_hoggar"] },
      children: [
        {
          path: "rubrique-1",
          name: "rubrique_1",
          component: () => import("./components/cse/Rubrique1.vue"),
        },
        {
          path: "rubrique-2",
          name: "rubrique_2",
          component: () => import("./components/cse/Rubrique2.vue"),
        },
        {
          path: "rubrique-3",
          name: "rubrique_3",
          component: () => import("./components/cse/Rubrique3.vue"),
        },
        {
          path: "rubrique-4",
          name: "rubrique_4",
          component: () => import("./components/cse/Rubrique4.vue"),
        },
      ],
    },
    {
      path: "/formations-manager",
      name: "FormationManager",
      component: () => import("./views/FormationsManager.vue"),
      meta: { permission: "manage_mooc" },
      children: [
        {
          path: ":id",
          name: "FormationManager_frm",
          props: true,
          component: () => import("./components/profil/FormationsManagerItem.vue"),
          meta: { permission: "manage_mooc" },
        },
      ],
    },
    {
      path: "/sirh",
      name: "Sirh",
      component: () => import("./views/Collaborateurs.vue"),
      redirect: "/sirh/dashboard",
      children: [
        {
          path: "dashboard",
          name: "sirh_dashboard",
          component: () => import("./components/collaborateurs/dashboard/Index.vue"),
          meta: { permission: "manage_sirh" },
        },
        {
          path: "collaborateurs/:id",
          name: "sirh_collaborateur",
          props: true,
          component: () => import("./components/collaborateurs/profil/Profil.vue"),
          meta: { permission: "manage_sirh" },
          redirect: "collaborateurs/:id/infos",
          children: [
            {
              path: "infos",
              name: "sirh_collaborateur_infos",
              component: () => import("./components/collaborateurs/profil/forms/collaborateur/Infos.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "coordonnees",
              name: "sirh_collaborateur_coordonnees",
              component: () => import("./components/collaborateurs/profil/forms/collaborateur/Coordonnees.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "taches",
              name: "sirh_collaborateur_tasks",
              component: () => import("./components/collaborateurs/profil/forms/collaborateur/Tasks.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "contrats",
              name: "sirh_collaborateur_contrats",
              component: () => import("./components/collaborateurs/profil/forms/contrat/Contrats.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "contrats/:contrat_id",
              name: "sirh_collaborateur_contrat",
              component: () => import("./components/collaborateurs/profil/forms/contrat/ContratForm.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "exposition",
              name: "sirh_collaborateur_exposition",
              component: () => import("./components/collaborateurs/profil/forms/exposition/Expositions.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "dosimetrie",
              name: "sirh_collaborateur_dosimetrie",
              component: () => import("./components/collaborateurs/profil/forms/dosimetrie/Dosimetrie.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "medical",
              name: "sirh_collaborateur_medical",
              component: () => import("./components/collaborateurs/profil/forms/medical/Vms.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "medical/:vm_id",
              name: "sirh_collaborateur_vm",
              component: () => import("./components/collaborateurs/profil/forms/medical/VmsForm.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "formations",
              name: "sirh_collaborateur_formations",
              component: () => import("./components/collaborateurs/profil/forms/formation/Formations.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "habilitations",
              name: "sirh_collaborateur_habilitations",
              component: () => import("./components/collaborateurs/profil/forms/habilitation/Habilitations.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "competences",
              name: "sirh_collaborateur_competences",
              component: () => import("./components/collaborateurs/profil/forms/competence/Competences.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "entretiens",
              name: "sirh_collaborateur_entretiens",
              component: () => import("./components/collaborateurs/profil/forms/entretien/Index.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "acces",
              name: "sirh_collaborateur_acces",
              component: () => import("./components/collaborateurs/profil/forms/acces/Acces.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "materiels",
              name: "sirh_collaborateur_materiels",
              component: () => import("./components/collaborateurs/profil/forms/materiel/Materiels.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "documents",
              name: "sirh_collaborateur_documents",
              component: () => import("./components/collaborateurs/profil/forms/doc/Docs.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "divers",
              name: "sirh_collaborateur_divers",
              component: () => import("./components/collaborateurs/profil/forms/collaborateur/Divers.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "users",
              name: "sirh_collaborateur_users",
              component: () => import("./components/collaborateurs/profil/forms/users/users.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "admin",
              name: "sirh_collaborateur_admin",
              component: () => import("./components/collaborateurs/profil/forms/admin/admin.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "accueil",
              name: "sirh_collaborateur_accueil",
              component: () => import("./components/collaborateurs/profil/forms/accueil/index.vue"),
              meta: { permission: "manage_sirh" },
            },
            {
              path: "objectifs",
              name: "sirh_collaborateur_objectifs",
              component: () => import("./components/collaborateurs/profil/forms/objectifs/index.vue"),
              meta: { permission: "manage_sirh" },
            },
          ],
        },
        {
          path: "recherche",
          name: "sirh_recherche",
          component: () => import("./components/collaborateurs/recherche/Index.vue"),
          meta: { permission: "manage_sirh" },
        },
        {
          path: "plan_formation",
          name: "sirh_plan",
          component: () => import("./components/collaborateurs/plan/Index.vue"),
          meta: { permission: "manage_sirh" },
        },
      ],
    },
    {
      path: "/documents",
      name: "Documents",
      component: () => import("./views/Documents.vue"),
      redirect: "/documents/all",
      meta: { permission: "manage_documents" },
      children: [
        {
          path: ":request",
          name: "document_request",
          component: () => import("./components/documents/Liste.vue"),
          meta: { permission: "manage_documents" },
        },
      ],
    },
    {
      path: "/crm",
      name: "crm",
      component: () => import("./views/Affaires.vue"),
      redirect: "/crm/dashboard",
      meta: { permission: "manage_affaires" },
      children: [
        {
          path: "dashboard",
          name: "crm_dashboard",
          component: () => import("./components/affaires/Dashboard.vue"),
          meta: { permission: "manage_affaires" },
        },
        {
          path: "recherche",
          name: "crm_recherche",
          component: () => import("./components/affaires/recherche/index.vue"),
          meta: { permission: "manage_affaires" },
        },
        {
          path: "rex",
          name: "crm_dashboard_rex",
          component: () => import("./components/affaires/DashboardRex.vue"),
          meta: { permission: "manage_affaires" },
        },
        {
          path: "affaire/:id",
          name: "crm_affaire",
          props: true,
          component: () => import("./components/affaires/Index.vue"),
          redirect: "affaire/:id/infos",
          meta: { permission: "manage_affaires" },
          children: [
            {
              path: "infos",
              name: "crm_affaire_infos",
              component: () => import("./components/affaires/Infos.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "preparation",
              name: "crm_affaire_prepa",
              component: () => import("./components/affaires/Prepa.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "hse",
              name: "crm_affaire_hse",
              component: () => import("./components/affaires/Hse.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "cahier_des_charges",
              name: "crm_affaire_cdc",
              component: () => import("./components/affaires/Cdc.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "planning",
              name: "crm_affaire_planning",
              component: () => import("./components/affaires/Planning.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "planning/gant",
              name: "crm_affaire_gant",
              component: () => import("./components/affaires/Gantt.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "offre",
              name: "crm_affaire_offre",
              component: () => import("./components/affaires/Offre.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "chiffrage",
              name: "crm_affaire_chiffrage",
              component: () => import("./components/affaires/Chiffrage.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "commande",
              name: "crm_affaire_commande",
              component: () => import("./components/affaires/Commande.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "suivi",
              name: "crm_affaire_suivi",
              component: () => import("./components/affaires/Suivi.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "dosimetrie",
              name: "crm_affaire_dosi",
              component: () => import("./components/affaires/Dosimetrie.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "production",
              name: "crm_affaire_prod",
              component: () => import("./components/affaires/Prod.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "satisfaction",
              name: "crm_affaire_satisfaction",
              component: () => import("./components/affaires/Satisfaction.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "rex",
              name: "crm_affaire_rex",
              component: () => import("./components/affaires/Rex.vue"),
              meta: { permission: "manage_affaires" },
            },
            {
              path: "users",
              name: "crm_affaire_users",
              component: () => import("./components/affaires/Users.vue"),
              meta: { permission: "manage_affaires" },
            },
          ],
        },
      ],
    },
    {
      path: "/clients",
      name: "crm_clients",
      component: () => import("./views/Clients.vue"),
      children: [
        {
          path: ":id",
          name: "crm_client",
          props: true,
          component: () => import("./components/clients/Index.vue"),
          redirect: ":id/infos",
          meta: { permission: "manage_clients" },
          children: [
            {
              path: "infos",
              name: "crm_client_infos",
              component: () => import("./components/clients/Infos.vue"),
              meta: { permission: "manage_clients" },
            },
            {
              path: "coordonnees",
              name: "crm_client_coordonnees",
              component: () => import("./components/clients/Coordonnees.vue"),
              meta: { permission: "manage_clients" },
            },
            {
              path: "rdv",
              name: "crm_client_rdv",
              component: () => import("./components/clients/Rdv.vue"),
              meta: { permission: "manage_clients" },
            },
            {
              path: "suivi",
              name: "crm_client_suivi",
              component: () => import("./components/clients/Suivi.vue"),
              meta: { permission: "manage_clients" },
            },
            {
              path: "satisfaction",
              name: "crm_client_satisfaction",
              component: () => import("./components/clients/Satisfaction.vue"),
              meta: { permission: "manage_clients" },
            },
            {
              path: "users",
              name: "crm_client_users",
              component: () => import("./components/clients/users.vue"),
              meta: { permission: "manage_clients" },
            },
          ],
        },
      ],
    },
    {
      path: "/qsser",
      name: "qsser",
      component: () => import("./views/Qsser.vue"),
      redirect: "/qsser/ncs",
      children: [
        /* {
          path: "dashboard",
          name: "qsser_dashboard",
          component: () => import("./components/qsser/dashboard.vue"),
          meta: { permission: "manage_qsser" },
        },*/
        {
          path: "ncs",
          name: "qsser_ncs",
          component: () => import("./components/qsser/ncs.vue"),
          meta: { permission: "manage_qsser" },
        },
        {
          path: "actions",
          name: "qsser_actions",
          component: () => import("./components/qsser/actions.vue"),
          meta: { permission: "manage_qsser" },
        },
        {
          path: "animations",
          name: "qsser_animations",
          component: () => import("./components/qsser/animations.vue"),
          meta: { permission: "manage_qsser" },
        },
        {
          path: "evenements",
          name: "qsser_evenements",
          component: () => import("./components/qsser/evenements.vue"),
          meta: { permission: "manage_qsser" },
        },
        {
          path: "visites",
          name: "qsser_visites",
          component: () => import("./components/qsser/visites.vue"),
          meta: { permission: "manage_qsser" },
        },
      ],
    },
    {
      path: "/alertes",
      name: "alertes",
      component: () => import("./views/Alertes.vue"),
      meta: { permission: "manage_alertes" },
    },
    {
      path: "/materiel",
      name: "Materiel",
      component: () => import("./views/Materiels.vue"),
      redirect: "/materiel/demandes",
      children: [
        {
          path: "articles",
          name: "materiels_articles",
          component: () => import("./components/materiels/Articles.vue"),
          meta: { permission: "add_materiels_article" },
        },
        {
          path: "commandes",
          name: "materiels_commandes",
          component: () => import("./components/materiels/Commandes.vue"),
          meta: { permission: "show_materiels_commandes" },
        },
        {
          path: "demandes",
          name: "materiels_demandes",
          component: () => import("./components/materiels/Demandes.vue"),
          meta: { permission: "add_materiels_cmd" },
        },
        {
          path: "stocks",
          name: "materiels_stocks",
          component: () => import("./components/materiels/Stocks.vue"),
          meta: { permission: "add_materiels_stock" },
        },
        {
          path: "materiels",
          name: "materiels_materiels",
          component: () => import("./components/materiels/Materiels.vue"),
          meta: { permission: "add_materiels_suivis" },
        },
        {
          path: "fournisseurs",
          name: "materiels_fournisseurs",
          component: () => import("./components/materiels/Fournisseurs.vue"),
          //meta: { permission: "add_materiels_fournisseurs" },
          meta: { permission: "get_materiels" },
        },
      ],
    },
    {
      path: "/radioprotection",
      name: "radioprotection",
      component: () => import("./views/Radioprotection.vue"),
      meta: { permission: "manage_pcr" },
      children: [
        {
          path: "dashboard",
          name: "rp_dashboard",
          component: () => import("./components/radioprotection/Index.vue"),
          meta: { permission: "manage_pcr" },
        },
      ],
    },
    {
      path: "/vehicules",
      name: "vehicules",
      component: () => import("./components/vehicules/index.vue"),
    },
    {
      path: "/*",
      name: "Page404",
      component: Page404,
    },
  ],
});

router.beforeEach((to, from, next) => {
  to.matched.forEach((route) => {
    if (route.meta && route.meta.permission) {
      let permissionOK = false;

      const requiredPermissions = Array.isArray(route.meta.permission) ? route.meta.permission : [route.meta.permission];
      for (let i = 0; i < requiredPermissions.length; i++) {
        if (store.getters["user/havePermission"](requiredPermissions[i])) {
          permissionOK = true;
        }
      }

      if (permissionOK === true) {
        next();
      } else {
        const vm = new Vue();
        vm.$snotify.error("Vous n'avez pas accès à ce module");
        next(from.path);
      }
    }
    if (route.meta && route.meta.role) {
      if (store.getters["user/haveRole"](route.meta.role)) {
        next();
      } else {
        const vm = new Vue();
        vm.$snotify.error("Vous n'avez pas accès à ce module");
        next(from.path);
      }
    }
  });

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.getters["isConnected"]) {
      next();
    }
    next("/");
  } else {
    next();
  }
});

export default router;
