// initial state
// shape: [{ id, quantity }]
const state = {
  mode: "light",
  colors: {
    light: {
      color1: "#dfe3e5",
      color2: "#f7f9fb",
      color3: "#ffffff",
      color4: "#ffffff",
      color5: "#dc3545", // Danger
      color6: "#343a40",
      colorIconNavBar: "#000000",
      colorIconInput: "#e5e5e5",
      colorFocus: "6f42c1"
    },
    dark: {
      color1: "#24292d",
      color2: "#343a40",
      color3: "#2e343a",
      color4: "#24282d",
      color5: "#ffc107", // Warning
      color6: "#f8f9fa",
      colorIconNavBar: "#ffffff",
      colorIconInput: "#ffffff",
      colorFocus: "6f42c1"
    }
  }
};

// getters
const getters = {
  mode: state => {
    return state.mode;
  },
  darkMode: state => {
    if (state.mode === "light") {
      return false;
    }
    if (state.mode === "dark") {
      return true;
    }
  },
  colors: state => {
    if (state.mode === "light") {
      return state.colors.light;
    }
    if (state.mode === "dark") {
      return state.colors.dark;
    }
  }
};

// actions
const actions = {
  setDarkMode: ({ commit }) => {
    commit("SetMode", "dark");
  },

  setLightMode: ({ commit }) => {
    commit("SetMode", "light");
  },

  setColor1: ({ commit }, color) => {
    commit("SetColor1", color);
  },

  setColor2: ({ commit }, color) => {
    commit("SetColor2", color);
  },

  setColor3: ({ commit }, color) => {
    commit("SetColor3", color);
  },

  setColor4: ({ commit }, color) => {
    commit("SetColor4", color);
  },

  setColor5: ({ commit }, color) => {
    commit("SetColor5", color);
  }
};

// mutations
const mutations = {
  SetMode: (state, mode) => {
    state.mode = mode;
  },

  setColor1: (state, color) => {
    if (state.mode === "light") {
      state.colors.light.color1 = color;
    }
    if (state.mode === "dark") {
      state.colors.dark.color1 = color;
    }
  },

  setColor2: (state, color) => {
    if (state.mode === "light") {
      state.colors.light.color2 = color;
    }
    if (state.mode === "dark") {
      state.colors.dark.color2 = color;
    }
  },

  setColor3: (state, color) => {
    if (state.mode === "light") {
      state.colors.light.color3 = color;
    }
    if (state.mode === "dark") {
      state.colors.dark.color3 = color;
    }
  },

  setColor4: (state, color) => {
    if (state.mode === "light") {
      state.colors.light.color4 = color;
    }
    if (state.mode === "dark") {
      state.colors.dark.color4 = color;
    }
  },

  setColor5: (state, color) => {
    if (state.mode === "light") {
      state.colors.light.color5 = color;
    }
    if (state.mode === "dark") {
      state.colors.dark.color5 = color;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
