<template>
  <div id="main" :class="appClass" :style="{ backgroundColor: color }">
    <slot></slot>
    <div id="notifications"></div>
  </div>
</template>
<script>
export default {
  name: "AppScreen",
  components: {},
  methods: {
    test: function() {}
  },
  computed: {
    color: function() {
      return this.$store.getters["colors/colors"].color1;
    },

    appClass: function() {
      // LIGHT MODE
      let out = "";
      if (this.$route.path === "/" || this.$route.path === "/login") {
        out = "bg-light-home";
        if(!process.env.VUE_APP_BACKGROUND || process.env.VUE_APP_BACKGROUND === "1") {
          out = "bg-light-home-1";
        }

        if(process.env.VUE_APP_BACKGROUND === "2") {
          out = "bg-light-home-2";
        }

      }

      // DARK MODE
      if (this.$store.getters["colors/darkMode"]) {
        if (this.$route.path === "/" || this.$route.path === "/login") {
          out = "bg-dark-home";
          if(!process.env.VUE_APP_BACKGROUND || process.env.VUE_APP_BACKGROUND === "1") {
            out = "bg-dark-home-1";
          }

          if(process.env.VUE_APP_BACKGROUND === "2") {
            out = "bg-dark-home-2";
          }
        }
      }



      return out;
    }
  },
  mounted() {}
};
</script>
<style>
@import "~vue-snotify/styles/material.css";
</style>
