import api from "@/services/api/comptes.js";
import { getField, updateField } from "vuex-map-fields";
// initial state
const state = {
  comptes: [],
  compte: {},
  data: {}
};

// getters
const getters = {
  // Toutes les comptes
  comptes: function (state) {
    return state.comptes;
  },
  compte: function (state) {
    return state.compte;
  },
  data: function (state) {
    return state.data;
  },
  getField
};

// actions
const actions = {
  getComptes({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then(response => {
          resolve(response);
          commit("setComptes", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getCompte({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getItem(id)
        .then(response => {
          resolve(response);
          commit("setCompte", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getData()
        .then(response => {
          resolve(response);
          commit("setData", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  create({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .create(state.compte)
        .then((response) => {
          commit("create", response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .update(state.compte.id, state.compte)
        .then(() => {
          commit("update");
          resolve(state.compte);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  delete({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .delete(state.compte.id)
        .then(() => {
          commit("delete");
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setComptes(state, data) {
    state.comptes = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setCompte(state, data) {
    state.compte = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setData(state, data) {
    state.data = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  create(state, data) {
    state.comptes.push(data);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
  */
  update(state) {
    //let compte = state.comptes.indexOf(state.compte);
    const index = state.comptes.findIndex(x => x.id === state.compte.id)
    state.comptes[index].description = state.compte.description
    state.comptes[index].compte_etat_id = state.compte.compte_etat_id
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  delete(state) {
    const index = state.comptes.findIndex(x => x.id === state.compte.id)
    state.comptes.splice(index, 1);
    state.compte = {}
  },

  updateField
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
