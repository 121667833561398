import api from "@/services/api/materiels.js";
import { getField, updateField } from "vuex-map-fields";
// initial state
const state = {
  data: {},
  articles: [],
  commandes: [],
  commandeArticles: [],
  stocks: [],
  materiels: [],
  materielsVerifs: [],
  fournisseurs: [],
  evaluations: [],
};

// getters
const getters = {
  getField,

  data: function(state) {
    return state.data;
  },

  fournisseurs: function(state) {
    return state.fournisseurs;
  },

  commandeArticles: function(state) {
    return state.commandeArticles;
  },

  articles: function(state) {
    return state.articles;
  },
};

// actions
const actions = {
  getAll({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then((response) => {
          resolve(response);
          commit("setAll", response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getData()
        .then((response) => {
          resolve(response);
          commit("setData", response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createMateriel({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createMateriel(data)
        .then((response) => {
          commit("createMateriel", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMateriel({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateMateriel(data)
        .then((response) => {
          commit("updateMateriel", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMateriel({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteMateriel(data)
        .then(() => {
          commit("deleteMateriel", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportMateriels(data) {
    return new Promise((resolve, reject) => {
      api
        .exportMateriels(data)
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
          anchor.target = "_blank";
          anchor.download = "export-materiels.csv";
          anchor.click();

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createMaterielVerif({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createMaterielVerif(data)
        .then((response) => {
          commit("createMaterielVerif", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateMaterielVerif({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateMaterielVerif(data)
        .then((response) => {
          commit("updateMaterielVerif", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteMaterielVerif({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteMaterielVerif(data)
        .then(() => {
          commit("deleteMaterielVerif", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createArticle(data)
        .then((response) => {
          commit("createArticle", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateArticle(data)
        .then((response) => {
          commit("updateArticle", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteArticle(data)
        .then(() => {
          commit("deleteArticle", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCommande({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createCommande(data)
        .then((response) => {
          commit("createCommande", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCommande({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateCommande(data)
        .then((response) => {
          commit("updateCommande", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCommande({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteCommande(data)
        .then(() => {
          commit("deleteCommande", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCommandeArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createCommandeArticle(data)
        .then((response) => {
          commit("createCommandeArticle", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCommandeArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateCommandeArticle(data)
        .then((response) => {
          commit("updateCommandeArticle", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCommandeArticle({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteCommandeArticle(data)
        .then(() => {
          commit("deleteCommandeArticle", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createStock({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createStock(data)
        .then((response) => {
          commit("createStock", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateStock({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateStock(data)
        .then((response) => {
          commit("updateStock", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteStock({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteStock(data)
        .then(() => {
          commit("deleteStock", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createFournisseur({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createFournisseur(data)
        .then((response) => {
          commit("createFournisseur", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateFournisseur({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateFournisseur(data)
        .then((response) => {
          commit("updateFournisseur", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteFournisseur({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteFournisseur(data)
        .then(() => {
          commit("deleteFournisseur", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createFournisseurEval({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createFournisseurEval(data)
        .then((response) => {
          commit("createFournisseurEval", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateFournisseurEval({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateFournisseurEval(data)
        .then((response) => {
          commit("updateFournisseurEval", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteFournisseurEval({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteFournisseurEval(data)
        .then(() => {
          commit("deleteFournisseurEval", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setAll(state, data) {
    state.articles = data.articles;
    state.commandes = data.commandes;
    state.commandeArticles = data.commandeArticles;
    state.stocks = data.stocks;
    state.materiels = data.materiels;
    state.materielsVerifs = data.verifs;
    state.fournisseurs = data.fournisseurs;
    state.evaluations = data.evaluations;
  },

  setData(state, data) {
    state.data = data;
  },

  createMateriel(state, data) {
    state.materiels.push({
      id: data.id,
      identifiant: "",
      description: "",
      num_serie: "",
      lieu_stockage: "",
      marque: "",
      modele: "",
      image: "",
      materiel_etat_id: null,
      materiel_type_id: null,
      materiel_collab_id: null,
      materiel_societe_id: null,
      materiel_fournisseur_id: null,
      date_commande: null,
      date_livraison: null,
      date_validite: null,
      commentaire: "",
      tags: "",
      materiel_new: true,
    });
  },

  updateMateriel() {
    //console.log(state, data)
  },

  deleteMateriel(state, data) {
    const index = state.materiels.findIndex((x) => x.id === data.id);
    state.materiels.splice(index, 1);
  },

  createMaterielVerif(state, data) {
    state.materielsVerifs.push({
      id: data.id,
      materiel_id: data.materiel_id,
      verif_type_id: null,
      date_debut: null,
      date_validite: null,
      fournisseur_id: null,
      verif_interne: false,
      commentaire: "",
    });
  },

  updateMaterielVerif() {
    //console.log(state, data)
  },

  deleteMaterielVerif(state, data) {
    const index = state.materielsVerifs.findIndex((x) => x.id === data.id);
    state.materielsVerifs.splice(index, 1);
  },

  createArticle(state, data) {
    state.articles.push({
      id: data.id,
      description: "",
      specs: "",
      image: "",
      stock_securite: 0,
      prix_unitaire_ht: 0,
      fournisseur_id: null,
      type_id: null,
      new: data.new,
    });
  },

  updateArticle(state, data) {
    const index = state.articles.findIndex((x) => x.id === data.id);
    state.articles[index].new = data.new;
  },

  deleteArticle(state, data) {
    const index = state.articles.findIndex((x) => x.id === data.id);
    state.articles.splice(index, 1);
  },

  createCommande(state, data) {
    state.commandes.push({
      id: data.id,
      etat_id: data.etat_id,
      user_id: data.user_id,
      fournisseur_id: null,
      etablissement_livraison_id: null,
      adresse_livraison: null,
      contact_livraison: null,
      societe_facturation_id: null,
      reference: null,
      validation: null,
      commentaire: "",
    });
  },
  updateCommande() {
    //console.log(state, data);
  },
  deleteCommande(state, data) {
    const index = state.commandes.findIndex((x) => x.id === data.id);
    state.commandes.splice(index, 1);
  },

  createCommandeArticle(state, data) {
    state.commandeArticles.push({
      id: data.id,
      article_id: data.article_id,
      commande_id: data.commande_id,
      quantite: data.quantite,
      quantite_commande: 0,
      information: "",
      livraison_date: null,
      livraison_quantite: 0,
      livraison_conforme: 0,
    });
  },

  updateCommandeArticle(state, data) {
    const index = state.commandeArticles.findIndex((x) => x.id === data.id);
    state.commandeArticles[index].quantite = data.quantite;
  },
  deleteCommandeArticle(state, data) {
    const index = state.commandeArticles.findIndex((x) => x.id === data.id);
    state.commandeArticles.splice(index, 1);
  },

  createStock(state, data) {
    state.stocks.push({
      article_id: data.article_id,
      quantite: data.quantite,
    });
  },
  updateStock(state, data) {
    console.log(state, data);
  },
  deleteStock(state, data) {
    const index = state.stocks.findIndex((x) => x.id === data.id);
    state.stocks.splice(index, 1);
  },
  createFournisseur(state, data) {
    state.fournisseurs.push({
      id: data.id,
      etat: true,
      date_debut: null,
      date_fin: null,
      description: "",
      raison_sociale: "",
      activite: "",
      adresse: "",
      telephone: "",
      email: "",
      type_service: "",
      commentaire: "",
      agree: true,
      e_attestation: true,
    });
  },
  updateFournisseur() {
    //
  },
  deleteFournisseur(state, data) {
    const index = state.fournisseurs.findIndex((x) => x.id === data.id);
    state.fournisseurs.splice(index, 1);
  },

  createFournisseurEval(state, data) {
    state.evaluations.push({
      id: data.id,
      fournisseur_id: data.fournisseur_id,
      eval_date: null,
      eval_1: 0,
      eval_2: 0,
      eval_3: 0,
      eval_4: 0,
      eval_5: 0,
      commentaire: "",
    });
  },
  updateFournisseurEval() {
    //
  },

  deleteFournisseurEval(state, data) {
    const index = state.evaluations.findIndex((x) => x.id === data.id);
    state.evaluations.splice(index, 1);
  },

  updateField,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
