import request from "@/request.js";

const BASE = "/comptes";

export default {
  getAll() {
    return request.get(BASE);
  },
  getItem(id) {
    return request.get(BASE + "/" + id);
  },
  getData() {
    return request.get(BASE + "/data");
  },
  create(payload) {
    return request.put(BASE, payload);
  },
  update(id, payload) {
    return request.post(BASE + "/" + id, payload)
  },
  delete(id) {
    return request.delete(BASE + "/" + id);
  }
};
