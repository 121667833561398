import api from "@/services/api/alertes.js";

import { getField, updateField } from "vuex-map-fields";

const state = {
  alertes: [],
  data: {}
};

// getters
const getters = {
  getField,
  // Toutes les affaires
  alertes: function (state) {
    return state.alertes;
  },
  // Toutes les affaires
  data: function (state) {
    return state.data;
  }
}

// actions
const actions = {
  getAlertes({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then(response => {
          commit("setAlertes", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getData()
        .then(response => {
          commit("setData", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  checkAlert({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .checkAlert(data)
        .then(response => {
          commit("checkAlert", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
}
// mutations
const mutations = {
  updateField,
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setAlertes(state, data) {
    state.alertes = data;
  },

  /**
 * Mutation
 * @return {state}
 * @param {object} state
 */
  checkAlert(state, data) {
    const index = state.alertes.findIndex(x => x.id === data.id)
    state.alertes[index].deleted = data.deleted
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
