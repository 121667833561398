import request from "@/request.js";

const BASE = "/dosi";

export default {
  getAll() {
    return request.get(BASE);
  },
  getItem(id) {
    return request.get(BASE + "/" + id);
  },
  getData() {
    return request.get(BASE + "/data");
  },
  getByAffaire(affaire_id) {
    return request.get(BASE + "/byaffaire/" + affaire_id);
  },
  getByCollab(collab_id) {
    return request.get(BASE + "/bycollaborateur/" + collab_id);
  },
  // ================================================
  create(payload) {
    return request.put(BASE, {
      dosi_collaborateur_id: payload.dosi_collaborateur_id,
      dosi_affaire_id: payload.dosi_affaire_id,
      dosi_dose: payload.dosi_dose,
      dosi_date_debut: payload.dosi_date_debut,
      dosi_date_fin: payload.dosi_date_fin,
      dosi_type_id: payload.dosi_type_id,
      dosi_commentaire: payload.dosi_commentaire,
    });
  },
  update(payload) {
    return request.post(BASE + "/" + payload.id, {
      dosi_collaborateur_id: payload.dosi_collaborateur_id,
      dosi_affaire_id: payload.dosi_affaire_id,
      dosi_edp_id: payload.dosi_edp_id,
      dosi_type_id: payload.dosi_type_id,
      dosi_date_debut: payload.dosi_date_debut,
      dosi_date_fin: payload.dosi_date_fin,
      dosi_dose: payload.dosi_dose,
      dosi_commentaire: payload.dosi_commentaire,
    });
  },
  delete(id) {
    return request.delete(BASE + "/" + id);
  },
  import(payload) {
    return request.put(BASE + "/import", payload);
  },
  // ================================================
  createEdp(payload) {
    return request.put(BASE + "/edp", {
      affaire_id: payload.affaire_id,
    });
  },
  updateEdp(payload) {
    return request.post(BASE + "/edp", {
      id: payload.id,
      affaire_id: payload.affaire_id,
      description: payload.description,
      site: payload.site,
      tranche: payload.tranche,
      nbr_intervenant_total: payload.nbr_intervenant_total,
      commentaire: payload.commentaire
    });
  },
  deleteEdp(payload) {
    return request.delete(BASE + "/edp/" + payload.id);
  },

  // ================================================
  createEdpPhase(payload) {
    return request.put(BASE + "/edp/phase", {
      edp_id: payload.edp_id,
    });
  },
  updateEdpPhase(payload) {
    return request.post(BASE + "/edp/phase", {
      id: payload.id,
      edp_id: payload.edp_id,
      phase: payload.phase,
      ded: payload.ded,
      temps: payload.temps,
      coef: payload.coef,
      iteration: payload.iteration,
      nbre_ope: payload.nbre_ope,
      dose_indiv: payload.dose_indiv,
      dose_collec: payload.dose_collec
    });
  },
  deleteEdpPhase(payload) {
    return request.delete(BASE + "/edp/phase/" + payload.id);
  },
};
