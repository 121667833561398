import request from "@/request.js";

export default {
  login(payload) {
    return request.post("/login", payload);
  },
  logout() {
    return request.get("/logout");
  },
  changePassword(payload) {
    return request.post("/change_password", payload);
  },
};
