import api from "@/services/api/chiffrages.js";
import { getField, updateField } from "vuex-map-fields";
import { filterFloat } from "@/helpers.js";

// initial state
const state = {
  chiffrages: [
  ],
  data: {},
  statut: ""
};

// getters
const getters = {
  getField,

  prixVenteTotal: function (state) {
    let prixVenteTotal = 0;
    let arr = state.chiffrages;
    arr.forEach(item => {
      prixVenteTotal = prixVenteTotal + item.prixVente
    });
    return filterFloat(prixVenteTotal);
  },

  margeMoyenne: function (state) {
    let prixVenteTotal = 0;
    let prixRevientTotal = 0;
    let arr = state.chiffrages;
    arr.forEach(item => {
      prixVenteTotal = prixVenteTotal + parseFloat(item.prixVente)
      prixRevientTotal = prixRevientTotal + parseFloat(item.prixRevientTotal)
    });
    let v = (prixVenteTotal - prixRevientTotal) / prixVenteTotal * 100
    return v;
  },
};

// actions
const actions = {
  newChiffrage({ commit }) {
    commit('newChiffrage')
  },
  get({ commit }, affaire_id) {
    return new Promise((resolve, reject) => {
      api
        .getItems(affaire_id)
        .then(response => {
          commit('getChiffrages', response.data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  load({ commit }, phases) {
    let __chiffrages = [];
    if (phases) {
      phases.forEach(phase => {
        phase.chiffrages.forEach(i => {
          __chiffrages.push(i)
        })
      })
      commit('getChiffrages', __chiffrages)
    }
  },
  create({ commit }, phase_id) {
    return new Promise((resolve, reject) => {
      api
        .create(phase_id)
        .then(response => {
          commit('newChiffrage', { id: response.data, chiffrage_phase_id: phase_id })
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update({ commit }, id) {
    const index = state.chiffrages.findIndex(x => x.id === id)
    const chiffrage = state.chiffrages[index];
    return new Promise((resolve, reject) => {
      api
        .update(id, chiffrage)
        .then(() => {
          commit("updateChiffrage", id);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .delete(id)
        .then(() => {
          commit('deleteChiffrage', id)
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {
  updateField,

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  newChiffrage(state, data) {
    state.chiffrages.push({
      id: data.id,
      chiffrage_type_id: 1,
      chiffrage_phase_id: data.chiffrage_phase_id,
      description: "Nouveau chiffrage",
      chiffrage_collaborateur_id: null,
      contrat_id: null,
      salaire_annuel: 0,
      indeminite_journaliere: 0,
      jours_prevus: 0,
      taux_journalier: 0,
      frais_divers: 0,
      frais_directs: 0,
      prixVente: 0,
      salaireCharge: 0,
      prixRevient: 0,
      prixRevientTotal: 0,
      margeBrute: 0,
      margeBruteEuros: 0
    })
  },
  getChiffrages(state, data) {
    state.chiffrages = data;
  },
  updateChiffrage(state, id) {
    state.statut = "updated " + id
  },
  deleteChiffrage(state, id) {
    const index = state.chiffrages.findIndex(x => x.id === id)
    state.chiffrages.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
