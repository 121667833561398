import api from "@/services/api/productions.js";
import { filterFloat } from "@/helpers.js";
import { getField, updateField } from "vuex-map-fields";
// initial state
const state = {
  productions: [],
  chiffrages: []
};

// getters
const getters = {
  getField,

  totalProduit: function (state) {
    let totalProduit = 0;
    let arr = state.productions;
    arr.forEach(item => {
      totalProduit = totalProduit + item.montant
    });
    return filterFloat(totalProduit);
  },

  totalChiffrages: function (state, prod_id) {
    let totalChiffrages = 0;
    let arr = state.chiffrages;
    arr.forEach(item => {
      if (item.production_id === prod_id) {
        totalChiffrages = totalChiffrages + item.production
      }
    });
    return filterFloat(totalChiffrages);
  },
};

// actions
const actions = {
  getProductions({ commit }, data) {
    commit("getProductions", data);
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .create(data)
        .then(response => {
          commit("create", response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .update(data)
        .then(response => {
          commit('update', data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  delete({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .delete(data.id)
        .then(() => {
          commit("delete", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        })

    });
  },
  createProdChiffrage({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createProdChiffrage(data)
        .then(response => {
          commit("createProdChiffrage", response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateProdChiffrage({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateProdChiffrage(data)
        .then(response => {
          commit('update', data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  deleteProdChiffrage({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteProdChiffrage(data.id)
        .then(() => {
          commit("deleteProdChiffrage", data);
          resolve();
        })
        .catch(error => {
          reject(error);
        })

    });
  },
};

// mutations
const mutations = {

  getProductions(state, data) {
    state.productions = data;
    state.chiffrages = [];
    state.productions.forEach(prod => {
      prod.production_chiffrages.forEach(chiffrage => {
        state.chiffrages.push(chiffrage)
      })
    });
  },
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  create(state, data) {

    let created = {
      id: data.prod.id,
      affaire_id: data.prod.affaire_id,
      type_id: data.prod.type_id,
      mois: data.prod.mois,
      description: data.prod.description,
      montant: data.prod.montant,
      facture: data.prod.facture,
      commentaire: data.prod.commentaire,
      production_chiffrages: data.prodChiffrages
    }
    state.productions.push(created);

    created.production_chiffrages.forEach(chiffrage => {
      state.chiffrages.push(chiffrage);
    })
  },

  update() {
    //const index = state.productions.findIndex(x => x.id === data.id)
    //state.productions[index].collaborateur = data
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  delete(state, data) {
    const index = state.productions.findIndex(x => x.id === data.id)
    state.productions.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createProdChiffrage() {
    //  
  },

  updateProdChiffrage() {
    /*const index = state.chiffrages.findIndex(x => x.id === data.id)
    state.chiffrages[index].nbre_jours_travailles = data.nbre_jours_travailles
    state.chiffrages[index].avancement = data.avancement
    state.chiffrages[index].production = data.production*/
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteProdChiffrage() {
    //const index = state.productions.findIndex(x => x.id === data.id)
    //state.productions.splice(index, 1);
  },


  updateField
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
