export default {
  data() {
    return {
      w: 0
    };
  },
  methods: {
    handleResize() {
      this.w = window.innerWidth;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
}