import request from "@/request.js";

const BASE = "/clients";

export default {
  getAll() {
    return request.get(BASE);
  },
  getItem(id) {
    return request.get(BASE + "/" + id);
  },
  getData() {
    return request.get(BASE + "/data");
  },
  create(payload) {
    return request.put(BASE, {
      client_compte_id: payload.client_compte_id,
      client_type_id: payload.client_type_id,
      client_region_id: payload.client_region_id,
      client_site_id: payload.client_site_id,
      client_nom: payload.client_nom,
      client_prenom: payload.client_prenom,
      client_poste: payload.client_poste,
      client_service: payload.client_service,
      client_adresse: payload.client_adresse,
      client_mail1: payload.client_mail1,
      client_mail2: payload.lient_mail2,
      client_mail3: payload.client_mail3,
      client_tel1: payload.client_tel1,
      client_tel2: payload.client_tel2,
      client_tel3: payload.client_tel3,
      client_tel_fax: payload.client_tel_fax,
      client_commentaire: payload.client_commentaire
    }
    );
  },
  update(payload) {
    return request.post(BASE, {
      id: payload.id,
      client_compte_id: payload.client_compte_id,
      client_type_id: payload.client_type_id,
      client_region_id: payload.client_region_id,
      client_site_id: payload.client_site_id,
      client_nom: payload.client_nom,
      client_prenom: payload.client_prenom,
      client_poste: payload.client_poste,
      client_service: payload.client_service,
      client_adresse: payload.client_adresse,
      client_mail1: payload.client_mail1,
      client_mail2: payload.lient_mail2,
      client_mail3: payload.client_mail3,
      client_tel1: payload.client_tel1,
      client_tel2: payload.client_tel2,
      client_tel3: payload.client_tel3,
      client_tel_fax: payload.client_tel_fax,
      client_commentaire: payload.client_commentaire
    })
  },
  delete(id) {
    return request.delete(BASE + "/" + id);
  },
  // ================================================
  createSuivi(client_id) {
    return request.put(BASE + "/suivi", { suivi_client_id: client_id });
  },
  updateSuivi(payload) {
    return request.post(BASE + "/suivi", {
      id: payload.id,
      suivi_date: payload.suivi_date,
      suivi_client_id: payload.suivi_client_id,
      description: payload.description,
      commentaire: payload.commentaire
    });

  },
  deleteSuivi(id) {
    return request.delete(BASE + "/suivi" + "/" + id);
  },
  // ================================================
  createSatisfaction(client_id) {
    return request.put(BASE + "/satisfaction", { satisfaction_client_id: client_id });
  },
  updateSatisfaction(payload) {
    return request.post(BASE + "/satisfaction", {
      id: payload.id,
      satisfaction_date: payload.satisfaction_date,
      satisfaction_client_id: payload.satisfaction_client_id,
      satisfaction_description: payload.satisfaction_description,
      satisfaction_note_commerce: payload.satisfaction_note_commerce,
      satisfaction_note_pilotage: payload.satisfaction_note_pilotage,
      satisfaction_note_technique: payload.satisfaction_note_technique,
      satisfaction_note_qsser: payload.satisfaction_note_qsser,
      satisfaction_note_prix: payload.satisfaction_note_prix,
      satisfaction_affaire_id: payload.satisfaction_affaire_id,
      satisfaction_commentaire: payload.satisfaction_commentaire,
    });

  },
  deleteSatisfaction(id) {
    return request.delete(BASE + "/satisfaction" + "/" + id);
  },
  // ================================================
  createRdv(client_id) {
    return request.put(BASE + "/rdv", { rdv_client_id: client_id });
  },
  updateRdv(payload) {
    return request.post(BASE + "/rdv", {
      id: payload.id,
      rdv_date: payload.rdv_date,
      rdv_client_id: payload.rdv_client_id,
      rdv_type_id: payload.rdv_type_id,
      rdv_notes: payload.rdv_notes
    });

  },
  deleteRdv(id) {
    return request.delete(BASE + "/rdv" + "/" + id);
  },
  autorizeUser(payload) {
    return request.post(BASE + "/users/autorize", { user_id: payload.user_id, client_id: payload.client_id });
  },
  unautorizeUser(payload) {
    return request.post(BASE + "/users/unautorize", { user_id: payload.user_id, client_id: payload.client_id });
  },
};
