import Vue from "vue";
import moment from "moment";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import request from "./request";
import VTooltip from "v-tooltip";
import JsonExcel from "vue-json-excel";
import VueApexCharts from "vue-apexcharts";
import Snotify, { SnotifyPosition } from "vue-snotify";

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    showProgressBar: false,
  },
};

Vue.use(Snotify, options);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VTooltip);
VTooltip.enabled = window.innerWidth > 768;
Vue.config.productionTip = false;
Vue.prototype.$http = request;
Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale("fr");

Vue.filter("striphtml", function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

import VueWorker from "vue-worker";
Vue.use(VueWorker);

Vue.component("apexchart", VueApexCharts);

Vue.directive("color1", function (el, binding) {
  // Exemple : <div @click="tab = 1" v-color-1="tab == 1">Demandes d'achat</div>
  if (binding.value == true) el.style.color = store.getters["colors/colors"].color5;
  else el.style.color = store.getters["colors/colors"].color6;
});

Vue.directive("background1", function (el) {
  el.style.backgroundColor = store.getters["colors/colors"].color1;
});

Vue.directive("background2", function (el) {
  el.style.backgroundColor = store.getters["colors/colors"].color2;
});

Vue.directive("background3", function (el) {
  el.style.backgroundColor = store.getters["colors/colors"].color3;
});

Vue.directive("background4", function (el) {
  el.style.backgroundColor = store.getters["colors/colors"].color4;
});

Vue.directive("background5", function (el) {
  el.style.backgroundColor = store.getters["colors/colors"].color5;
});

window.matchMedia("(prefers-color-scheme: dark)").addListener(function (e) {
  if (e.matches) store.dispatch("colors/setDarkMode");
  if (!e.matches) store.dispatch("colors/setLightMode");
});

new Vue({
  store,
  router,
  el: "#app",
  render: (h) => h(App),
});
