<template>
  <div class="">
    <div class="">
      <div class="text-center">
        <img :src="'/images/' + image" class="imgHome mt-5 mb-4" alt />
        <h3>{{ slogan }}</h3>
        <router-link v-if="isLogged" to="/ma-page" class="m-5 btn btn-primary btn-lg">
          Accéder à ma page personnelle
        </router-link>
      </div>
      <div v-if="!passwordChanged && isLogged">
        <modal id="pass">
          <div class="p-3 p-lg-5">
            <h5>Changer votre mot de passe</h5>
            <p>
              Pour garantir la sécurité des informations du groupe Arkadia vous devez choisir un mot de passe complexe
              et difficile à deviner. 8 caratères sont exigés.
            </p>
            <div class="">
              <BaseInput
                class
                v-model="myPassword1"
                inputText="Nouveau mot de passe"
                inputType="password"
                :errors="feedback.password"
                modeIOS
              ></BaseInput>
              <BaseInput
                class
                v-model="myPassword2"
                inputText="Répéter le mot de passe"
                inputType="password"
                :errors="feedback.password"
                modeIOS
              ></BaseInput>
              <button class="btn btn-primary ml-auto mt-3" type="button" :disabled="loading" @click="changeMyPassword">
                <span
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                  v-if="loading"
                ></span>
                <span class v-if="loading">Chargement...</span>
                <span class="d-flex" v-if="!loading">
                  <base-icon name="log-in" class></base-icon>
                  <span class="ml-2">Changer</span>
                </span>
              </button>
            </div>
          </div>
        </modal>
      </div>
      <div class="d-flex flex-column flex-md-row align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center flex-wrap" :class="color" v-if="isLogged">
          <div class="m-4" v-if="havePermission('manage_alertes')">
            <router-link to="/alertes" class="cursor-pointer" tag="div">
              <BaseIcon name="alert-triangle" width="52" height="52" />Alertes
            </router-link>
          </div>
          <div class="m-4" v-if="havePermission('manage_vehicules')">
            <router-link to="/vehicules" class="cursor-pointer" tag="div">
              <BaseIcon name="truck" width="52" height="52" />Véhicules
            </router-link>
          </div>
          <div class="m-4" v-if="havePermission('manage_candidats')">
            <router-link to="/candidats" class="cursor-pointer" tag="div">
              <BaseIcon name="user-plus" width="52" height="52" />Candidats
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="haveRole('admin')">
            <router-link to="/admin" class="cursor-pointer" tag="div">
              <BaseIcon name="key" width="52" height="52" />Admin
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="havePermission('manage_pcr')">
            <router-link to="/radioprotection/dashboard" class="cursor-pointer" tag="div">
              <BaseIcon name="shield" width="52" height="52" />PCR
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="havePermission('manage_materiels')">
            <router-link to="/materiel" class="cursor-pointer" tag="div">
              <BaseIcon name="box" width="52" height="52" />Matériel
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="havePermission('manage_qsser')">
            <router-link to="/qsser" class="cursor-pointer" tag="div">
              <BaseIcon name="award" width="52" height="52" />QSSER
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="havePermission('manage_clients')">
            <router-link to="/clients" class="cursor-pointer" tag="div">
              <BaseIcon name="book" width="52" height="52" />Clients
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="havePermission('manage_affaires')">
            <router-link to="/crm" class="cursor-pointer" tag="div">
              <BaseIcon name="briefcase" width="52" height="52" />Affaires
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="havePermission('manage_documents')">
            <router-link to="/documents" class="cursor-pointer" tag="div">
              <BaseIcon name="file" width="52" height="52" />Docs
            </router-link>
          </div>
          <div class="m-4 text-center" v-if="havePermission('manage_sirh')">
            <router-link to="/sirh" class="cursor-pointer" tag="div">
              <BaseIcon name="users" width="52" height="52" />SIRH
            </router-link>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <base-form
          v-if="!isLogged"
          class="text-left shadow-sm m-auto rounded-xl"
          :loading="loading"
          style="max-width: 700px"
          :updateBtn="false"
          :class="color"
        >
          <base-form-row class="p-4">
            <h2 class="text-center text-md-left">Veuillez vous connecter</h2>
            <div class="alert alert-danger" v-if="feedback.credential">
              {{ feedback.credential }}
            </div>
            <div class="d-flex align-items-center flex-column flex-md-row">
              <img :src="'/images/user.svg'" class="mb-3 m-md-0 mr-md-3" alt style="height: 7rem" />
              <div class="w-100">
                <BaseInput
                  class
                  :class="color"
                  v-model="email"
                  inputText="Identifiant"
                  inputType="email"
                  :errors="feedback.email"
                  @keyupEnter="connexion"
                  modeIOS
                  autofocus
                  autocomplete="login"
                ></BaseInput>
                <BaseInput
                  class
                  v-model="password"
                  inputText="Mot de passe"
                  inputType="password"
                  :errors="feedback.password"
                  @keyupEnter="connexion"
                  modeIOS
                  autocomplete="password"
                ></BaseInput>
              </div>
            </div>
            <button class="btn btn-primary ml-auto mt-3" type="button" :disabled="loading" @click="connexion">
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
                v-if="loading"
              ></span>
              <span class v-if="loading">Chargement...</span>
              <span class="d-flex" v-if="!loading">
                <base-icon name="log-in" class></base-icon>
                <span class="ml-2">Connexion</span>
              </span>
            </button>
          </base-form-row>
        </base-form>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "../components/bases/Input.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import { mapActions, mapGetters } from "vuex";
import BaseIcon from "@/components/bases/Icon.vue";
import Modal from "@/components/bases/Modal3.vue";
export default {
  name: "login",
  components: {
    BaseInput,
    BaseForm,
    BaseFormRow,
    BaseIcon,
    Modal,
  },
  data() {
    return {
      email: "",
      password: "",
      feedback: {},
      loading: false,
      showInfos: false,
      myPassword1: null,
      myPassword2: null,
    };
  },
  methods: {
    ...mapActions({ login: "user/login" }),
    ...mapActions({ changePassword: "user/changePassword" }),

    connexion: function () {
      this.feedback = {};
      this.loading = true;
      this.login({ email: this.email, password: this.password })
        .catch((error) => this.errHandler(error))
        .finally(() => (this.loading = false));
    },

    check: function () {
      this.loading = true;
      this.$http
        .get("/check")
        //.then((response) => ())
        .finally(() => (this.loading = false));
    },

    changeMyPassword: function () {
      if (this.myPassword1 === this.myPassword2) {
        this.loading = true;
        this.changePassword({ password: this.myPassword1 })
          .catch((error) => this.errHandler(error))
          .finally(() => (this.loading = false));
      } else {
        alert("Les mots de passes saisies ne sont pas identiques");
      }
    },

    errHandler: function (error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
      if (error.response.status === 401) {
        this.feedback = { credential: "Login ou mot de passe incorrect" };
        return;
      }
    },
  },
  mounted() {
    this.check();
  },
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),
    ...mapGetters({ haveRole: "user/haveRole" }),
    ...mapGetters({ passwordChanged: "user/passwordChanged" }),

    image() {
      if (this.$store.getters["colors/darkMode"]) {
        return process.env.VUE_APP_LOGO_DARK;
      }
      return process.env.VUE_APP_LOGO_LIGHT;
    },
    color() {
      if (this.$store.getters["colors/darkMode"]) {
        return "text-white";
      }
      return "text-dark";
    },
    isLogged() {
      return this.$store.getters["user/connected"];
    },
    slogan() {
      return process.env.VUE_APP_SLOGAN;
    },
  },
};
</script>
<style>
.imgHome {
  max-width: 600px;
}
.test {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  height: 60vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
}
</style>
