import request from "@/request.js";

const BASE = "/chiffrages";

export default {
  getItems(affaire_id) {
    return request.get(BASE + "/" + affaire_id);
  },
  create(affaire_id) {
    return request.put(BASE + "/" + affaire_id);
  },
  update(id, payload) {
    return request.post(BASE + "/" + id, payload)
  },
  delete(id) {
    return request.delete(BASE + "/" + id);
  }
};
