import Vue from "vue";
import Vuex from "vuex";

//-------------------------------
import user from "./modules/system/user";
import colors from "./modules/system/colors";
import system from "./modules/system/system";
import collaborateurs from "./modules/sirh/collaborateurs";
import documents from "./modules/qsser/documents";
import affaires from "./modules/crm/affaires";
import comptes from "./modules/crm/comptes";
import clients from "./modules/crm/clients";
import chiffrages from "./modules/crm/chiffrages";
import dosimetrie from "./modules/crm/dosimetrie";
import productions from "./modules/crm/productions";
import qsser from "./modules/qsser/qsser";
import alertes from "./modules/alertes/alertes";
import materiel from "./modules/qsser/materiel";
//-------------------------------

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    user,
    colors,
    system,
    collaborateurs,
    documents,
    affaires,
    comptes,
    clients,
    chiffrages,
    dosimetrie,
    productions,
    qsser,
    alertes,
    materiel
  },
  strict: debug
});
