<template>
  <div
    class="d-flex flex-column q-form"
    :class="{ 'q-form-row flex-xl-row': row, 'q-form-col': !row }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    row: {
      type: Boolean,
      default: false
    }
  },
  computed: {}
};
</script>
<style lang="css">
.q-form > div {
  margin-top: 1rem;
}
.q-form-row > div:first-child {
  margin-right: 2rem;
}
.q-form-row > div:only-child {
  margin-right: 0;
}
</style>