<template>
  <span :class="{'q-icon-container center': container}" v-html="iconSvg"></span>
</template>

<script>
import feather from "feather-icons";

export default {
  name: "BaseIcon",
  data() {
    return {
      active: false
    };
  },
  props: {
    name: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 20
    },
    height: {
      type: [Number, String],
      default: 20
    },
    color: {
      type: String,
      default: null
    },
    fill: {
      type: String,
      default: "none"
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    strokeWidth: function() {
      if (this.width > 30) {
        return 1;
      } else {
        return 2;
      }
    },
    iconSvg: function() {
      return feather.icons[this.name].toSvg({
        color: this.color,
        width: this.width,
        height: this.height,
        fill: this.fill,
        "stroke-linejoin": "round",
        "stroke-linecap": "round",
        "stroke-width": this.strokeWidth
      });
    }
  }
};
</script>

<style>
.q-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
