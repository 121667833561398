import request from "@/request.js";

const BASE = "/alertes";

export default {
  getAll() {
    return request.get(BASE + "/all");
  },
  checkAlert(payload) {
    return request.post(BASE + "/check", { id: payload.id })
  }
} 