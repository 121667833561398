import axios from "axios";
import store from "./store/index.js";
import Vue from "vue";
import router from "./router";

//const URL = "https://qwarkapi.espace-demo.fr";
//const URL = "https://api.arkadia-qwark.fr";
//const URL = "http://127.0.0.1:8000";
//const URL = "http://localhost:8000";
//const URL = "http://qwarkapi.com";
//"https://api.arkadia-qwark.fr";
//http://localhost:8888  - https://api.arkadia-qwark.fr

const URL = process.env.VUE_APP_API_URL; //on gère avec un .env maintenant

//const URL = "http://127.0.0.1:8000";

console.log("URL "+process.env.VUE_APP_API_URL);

/*
 * AXIOS - URL
 */
const Request = axios.create({
  baseURL: URL + "/api"
});

/*
 * AXIOS - REQUEST INTERCEPTOR
 */
Request.interceptors.request.use(
  config => {
    // DEFAULT CONFIG
    if (config.baseURL === URL + "/api") {
      config.headers["X-Requested-With"] = "XMLHttpRequest";
    }

    // CONFIG PAR DEFAULT A DESTINATION DU SERVEUR URL
    if (config.baseURL === URL + "/api" && config.url !== "login") {
      let token = store.getters["user/token"];
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
      }
      store.dispatch("user/logout");
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/*
 * AXIOS - REQUEST RESPONSE
 */
Request.interceptors.response.use(
  response => {
    const vm = new Vue();
  
    if(response.config.url === '/collaborateurs/byids') return response;

    if (response.config.url === "/login") {
      vm.$snotify.success("Vous êtes connecté");
      return response;
    }

    if (response.config.method === "put")
      vm.$snotify.success("L'élément a été ajouté");
    if (response.config.method === "post")
      vm.$snotify.success("L'élément a été mis à jour");
    if (response.config.method === "delete")
      vm.$snotify.success("L'élément a été supprimé");

    return response;
  },
  error => {
    const vm = new Vue();
    const statut = error.response.status;
    const url = error.response.config.url;

    if (statut === 401) {
      if (url === "/login") {
        vm.$snotify.error("Erreur d'identifiant ou mot de passe");
        return Promise.reject(error);
      }
      if (localStorage.getItem("connected"))
        vm.$snotify.error("Vous avez été déconnecté");
      store.dispatch("user/logout");
      router.push("/");
    }

    if (statut === 403) {
      vm.$snotify.error("Vous n'avez pas les droits nécessaires");
    }

    if (statut === 404) {
      vm.$snotify.error("Les données sont introuvables");
    }

    if (statut === 422) {
      vm.$snotify.error("Il y a une erreur de saisie");
    }

    if (statut === 500) {
      vm.$snotify.error("Il y a une problème sur le serveur");
    }

    return Promise.reject(error);
  }
);

export default Request;
