import api from "@/services/api/dosimetrie.js";

import { getField, updateField } from "vuex-map-fields";
// initial state
const state = {
  doses: [],
  edps: [],
  edpPhases: [],
};

// getters
const getters = {
  getField,
};

// actions
const actions = {
  getDoses({ commit }, data) {
    commit("getDoses", data);
  },
  getAll({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then((response) => {
          commit("setAllDoses", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .create(data)
        .then((response) => {
          commit("create", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  import({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .import(data)
        .then((response) => {
          commit("import", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .update(data)
        .then((response) => {
          commit("update", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .delete(data.id)
        .then(() => {
          commit("delete", data.id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEdp({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createEdp(data)
        .then((response) => {
          commit("createEdp", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEdp({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateEdp(data)
        .then((response) => {
          commit("updateEdp");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEdp({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteEdp(data)
        .then(() => {
          commit("deleteEdp", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createEdpPhase({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createEdpPhase(data)
        .then((response) => {
          commit("createEdpPhase", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateEdpPhase({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateEdpPhase(data)
        .then((response) => {
          commit("updateEdpPhase");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteEdpPhase({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteEdpPhase(data)
        .then(() => {
          commit("deleteEdpPhase", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  getDoses(state, data) {
    state.doses = data.doses;

    if (data.edps) {
      state.edpPhases = [];
      state.edps = data.edps;
      state.edps.forEach((edp) => {
        edp.phases.forEach((phase) => {
          state.edpPhases.push(phase);
        });
      });
    }
  },

  setAllDoses(state, data) {
    state.doses = data;
  },
  /**
   * Mutation
   */
  create(state, data) {
    state.doses.push({
      id: data.id,
      dosi_collaborateur_id: data.dosi_collaborateur_id,
      dosi_affaire_id: data.dosi_affaire_id,
      dosi_edp_id: data.dosi_edp_id,
      dosi_type_id: data.dosi_type_id,
      dosi_date_debut: data.dosi_date_debut,
      dosi_date_fin: data.dosi_date_fin,
      dosi_dose: parseFloat(data.dosi_dose),
      dosi_commentaire: data.dosi_commentaire,
      dosi_new: 1,
    });
  },

  import(state, data) {
    data.forEach((dose) => {
      let updated = false;

      state.doses.forEach((_dose) => {
        if (
          _dose.dosi_collaborateur_id == dose.dosi_collaborateur_id &&
          _dose.dosi_date_debut == dose.dosi_date_debut &&
          _dose.dosi_date_fin == dose.dosi_date_fin
        ) {
          _dose.dosi_dose = dose.dosi_dose;
          _dose.dosi_commentaire = dose.dosi_commentaire;
          updated = true;
        }
      });

      if (updated == false)
        state.doses.push({
          id: dose.id,
          dosi_collaborateur_id: dose.dosi_collaborateur_id,
          dosi_affaire_id: dose.dosi_affaire_id,
          dosi_edp_id: dose.dosi_edp_id,
          dosi_type_id: dose.dosi_type_id,
          dosi_date_debut: dose.dosi_date_debut,
          dosi_date_fin: dose.dosi_date_fin,
          dosi_dose: dose.dosi_dose,
          dosi_commentaire: dose.dosi_commentaire,
        });
    });
  },

  update(state, data) {
    const index = state.doses.findIndex((x) => x.id === data.id);
    state.doses[index].dosi_new = 0;
  },

  /**
   * Mutation
   */
  delete(state, id) {
    const index = state.doses.findIndex((x) => x.id === id);
    state.doses.splice(index, 1);
  },

  /**
   * Mutation
   */
  createEdp(state, data) {
    state.edps.push({
      id: data.id,
      affaire_id: data.affaire_id,
      description: "Sans titre",
      site: "",
      tranche: "",
      commentaire: "",
      nbr_intervenant_total: 0,
    });
  },

  updateEdp() {
    //
  },

  /**
   * Mutation
   */
  deleteEdp(state, data) {
    const index = state.edps.findIndex((x) => x.id === data.id);
    state.edps.splice(index, 1);
  },
  /**
   * Mutation
   */
  createEdpPhase(state, data) {
    state.edpPhases.push({
      id: data.id,
      edp_id: data.edp_id,
      phase: "Sans titre",
      ded: 0,
      temps: 0,
      coef: 0,
      nbre_ope: 0,
      iteration: 0,
      dose_indiv: 0,
      dose_collec: 0,
    });
  },

  updateEdpPhase() {
    //
  },

  /**
   * Mutation
   */
  deleteEdpPhase(state, data) {
    const index = state.edpPhases.findIndex((x) => x.id === data.id);
    state.edpPhases.splice(index, 1);
  },

  updateField,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
