import api from "@/services/api/affaires.js";

import { getField, updateField } from "vuex-map-fields";
// initial state
const state = {
  affaires: [],
  affaire: {},
  data: {},
  phases: [],
  links: [],
  chiffrages: [],
  suivis: [],
  satisfactions: [],
  doses: [],
  productions: [],
  rexs: [],
  users: [],
};

// getters
const getters = {
  getField,

  affaire: function (state) {
    return state.affaire;
  },
  data: function (state) {
    return state.data;
  },
  dataForGant: function (state) {
    return {
      data: state.phases,
      links: state.links,
    };
  },
  affaires: function (state) {
    return state.affaires.map((affaire) => {
      return {
        id: affaire.id,
        description: affaire.affaire_identifiant + " - " + affaire.affaire_libelle,
      };
    });
  },
};

// actions
const actions = {
  getAffaires({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getAll()
        .then((response) => {
          commit("setAffaires", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAffairesPcr({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getPcr()
        .then((response) => {
          commit("setAffairesPcr", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAffaire({ commit }, id) {
    return new Promise((resolve, reject) => {
      api
        .getItem(id)
        .then((response) => {
          commit("setAffaire", response.data);
          resolve(response);
        })
        .catch((error) => {
          commit("setAffaire", {});
          reject(error);
        });
    });
  },
  getData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getData()
        .then((response) => {
          commit("setData", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createAffaire({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createAffaire(data)
        .then((response) => {
          commit("createAffaire", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAffaire({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .updateAffaire(state.affaire.id, state.affaire)
        .then(() => {
          commit("updateAffaire");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAffaire({ commit, state }) {
    return new Promise((resolve, reject) => {
      api
        .deleteAffaire(state.affaire.id)
        .then(() => {
          commit("deleteAffaire");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPhase({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createPhase(data)
        .then((response) => {
          commit("createPhase", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePhase({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updatePhase(data)
        .then((response) => {
          commit("updatePhase", response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePhase({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deletePhase(data)
        .then(() => {
          commit("deletePhase", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPhaseLink({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createPhaseLink(data)
        .then((response) => {
          commit("createPhaseLink", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePhaseLink({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deletePhaseLink(data)
        .then(() => {
          commit("deletePhaseLink", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createSuivi({ commit }, affaire_id) {
    return new Promise((resolve, reject) => {
      api
        .createSuivi(affaire_id)
        .then((response) => {
          commit("createSuivi", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSuivi({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateSuivi(data)
        .then(() => {
          commit("updateSuivi", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => commit("stopLoading"));
    });
  },
  deleteSuivi({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteSuivi(data.id)
        .then(() => {
          commit("deleteSuivi", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => commit("stopLoading"));
    });
  },
  createSatisfaction({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .createSatisfaction(data)
        .then((response) => {
          commit("createSatisfaction", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSatisfaction({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateSatisfaction(data)
        .then(() => {
          commit("updateSatisfaction");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteSatisfaction({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .deleteSatisfaction(data.id)
        .then(() => {
          commit("deleteSatisfaction", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  autorizeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .autorizeUser(data)
        .then(() => {
          commit("autorizeUser", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  unautorizeUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .unautorizeUser(data)
        .then(() => {
          commit("unautorizeUser", data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setAffaires(state, data) {
    state.affaires = [];
    if (data.length > 0) {
      data.forEach((affaire) => {
        state.affaires.push({
          id: affaire.id,
          affaire_identifiant: affaire.affaire_identifiant,
          affaire_libelle: affaire.affaire_libelle,
          affaire_compte_id: affaire.affaire_compte_id,
          affaire_etat_id: affaire.affaire_etat_id,
          affaire_pilote_id: affaire.affaire_pilote_id,
          affaire_activite_id: affaire.affaire_activite_id,
          affaire_commande_montant: affaire.affaire_commande_montant,
          affaire_phases: affaire.affaire_phases,
          affaire_production: affaire.affaire_production,
          affaire_new: false,
        });
      });
    }
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setAffairesPcr(state, data) {
    state.affaires = [];
    if (data.length > 0) {
      data.forEach((affaire) => {
        state.affaires.push({
          id: affaire.id,
          affaire_identifiant: affaire.affaire_identifiant,
          affaire_libelle: affaire.affaire_libelle,
          affaire_compte_id: affaire.affaire_compte_id,
          affaire_etat_id: affaire.affaire_etat_id,
          affaire_pilote_id: affaire.affaire_pilote_id,
          affaire_activite_id: affaire.affaire_activite_id,
          affaire_dosimetrie: affaire.affaire_dosimetrie,
          affaire_edp: affaire.affaire_edp,
          affaire_new: false,
        });
      });
    }
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setAffaire(state, data) {
    state.affaire = {};
    state.phases = {};
    if (data) {
      state.affaire = data;
      state.suivis = data.affaire_suivis;
      state.satisfactions = data.affaire_satisfactions;
      state.doses = data.affaire_doses;
      state.productions = data.affaire_productions;
      state.rexs = data.affaire_rexs;
      state.phases = data.affaire_phases;
      state.links = data.affaire_links;
      state.users = data.affaire_users;
    }
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setCompte(state, data) {
    state.affaire.affaire_compte_id = data.id;
    state.affaire.affaire_comptes = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setClient(state, data) {
    state.affaire.affaire_client_id = data.id;
    state.affaire.affaire_clients = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  setData(state, data) {
    state.data = data;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createAffaire(state, data) {
    state.affaires.push({
      id: data.id,
      affaire_identifiant: data.affaire_identifiant,
      affaire_libelle: data.affaire_libelle,
      affaire_compte_id: data.affaire_compte_id,
      affaire_etat_id: data.affaire_etat_id,
      affaire_pilote_id: data.affaire_pilote_id,
      affaire_new: true,
    });
  },
  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateAffaire(state) {
    //let affaire = state.affaires.indexOf(state.affaire);
    const index = state.affaires.findIndex((x) => x.id === state.affaire.id);
    state.affaires[index].affaire_libelle = state.affaire.affaire_libelle;
    state.affaires[index].affaire_compte_id = state.affaire.affaire_compte_id;
    state.affaires[index].affaire_etat_id = state.affaire.affaire_etat_id;
    state.affaires[index].affaire_new = false;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteAffaire(state) {
    const index = state.affaires.findIndex((x) => x.id === state.affaire.id);
    state.affaires.splice(index, 1);
    state.affaire = {};
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createPhase(state, data) {
    state.phases.push({
      id: data.id,
      phase_affaire_id: data.id,
      text: "Nouvelle phase",
      start_date: null,
      duration: null,
      progress: null,
      phase_prix_vente: null,
      chiffrages: [],
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updatePhase(state, data) {
    const index = state.phases.findIndex((x) => x.id === data.id);
    state.phases[index].text = data.text;
    state.phases[index].start_date = data.start_date;
    state.phases[index].duration = data.duration;
    state.phases[index].progress = data.progress;
    state.phases[index].phase_prix_vente = data.phase_prix_vente;
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deletePhase(state, data) {
    const index = state.phases.findIndex((x) => x.id === data.id);
    state.phases.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createPhaseLink(state, data) {
    state.links.push({
      id: data.id,
      affaire_id: data.affaire_id,
      source: data.source,
      target: data.target,
      type: data.type,
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deletePhaseLink(state, data) {
    const index = state.links.findIndex((x) => x.id === data.id);
    state.phases.splice(index, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createSuivi(state, data) {
    state.suivis.push({
      id: data.id,
      visite_date: null,
      visite_affaire_id: data.visite_affaire_id,
      visite_synthese: "",
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateSuivi() {
    //
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteSuivi(state, data) {
    const index = state.affaire.affaire_suivis.findIndex((x) => x.id === data.id);
    state.affaire.affaire_suivis.splice(index, 1);

    const index2 = state.suivis.findIndex((x) => x.id === data.id);
    state.suivis.splice(index2, 1);
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  createSatisfaction(state, data) {
    state.satisfactions.push({
      id: data.id,
      satisfaction_affaire_id: data.satisfaction_affaire_id,
      satisfaction_client_id: data.satisfaction_client_id,
      satisfaction_date: null,
      satisfaction_description: "",
      satisfaction_note_commerce: 0,
      satisfaction_note_pilotage: 0,
      satisfaction_note_technique: 0,
      satisfaction_note_qsser: 0,
      satisfaction_note_prix: 0,
      satisfaction_commentaire: "",
    });
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  updateSatisfaction() {
    //
  },

  /**
   * Mutation
   * @return {state}
   * @param {object} state
   */
  deleteSatisfaction(state, data) {
    const index2 = state.satisfactions.findIndex((x) => x.id === data.id);
    state.satisfactions.splice(index2, 1);
  },

  autorizeUser(state, data) {
    state.users.push({
      user_id: data.user_id,
    });
  },

  unautorizeUser(state, data) {
    const index = state.users.findIndex((x) => x.user_id === data.user_id);
    state.users.splice(index, 1);
  },

  updateField,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
